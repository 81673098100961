import { ProfileType } from "../types";
import {
  PERMISSION_RIGHT_CODE__createDocument,
  PERMISSION_RIGHT_CODE__createIssue,
  PERMISSION_RIGHT_CODE__createAsset,
  PERMISSION_RIGHT_CODE__createRevision,
  PERMISSION_RIGHT_CODE__readDocument,
  PERMISSION_RIGHT_CODE__readIssue,
  PERMISSION_RIGHT_CODE__readRevision,
  PERMISSION_RIGHT_CODE__updateIssue,
} from "../libraries/enums/permissionRights";
import store from "./store";
import { ROUTER_PATH_LOGIN } from "./router";

const ISSUES = {
  to: "/issues",
  label: "Servisní požadavky",
  permissionRightIds: [
    PERMISSION_RIGHT_CODE__createIssue,
    PERMISSION_RIGHT_CODE__readIssue,
    PERMISSION_RIGHT_CODE__updateIssue,
  ],
};
const REVISIONS = {
  to: "/revisions",
  label: "Revize a kontroly",
  permissionRightIds: [
    PERMISSION_RIGHT_CODE__createRevision,
    PERMISSION_RIGHT_CODE__readRevision,
  ],
};
const DOCUMENTS = {
  to: "/documents",
  label: "Dokumenty",
  permissionRightIds: [
    PERMISSION_RIGHT_CODE__createDocument,
    PERMISSION_RIGHT_CODE__readDocument,
  ],
};
const ASSETS = {
  to: "/assets",
  label: "Zařízení",
  permissionRightIds: [
    PERMISSION_RIGHT_CODE__createDocument,
    PERMISSION_RIGHT_CODE__readDocument,
  ],
};

export const getNavigationMenuItemsForProfile = (profile: ProfileType) => {
  const items: { to: string; label: string; permissionRightIds: string[] }[] =
    [];
  [ISSUES, REVISIONS, DOCUMENTS, ASSETS].forEach((item) => {
    if (
      profile.permission.permissionRightIds.some((p) => {
        return (
          item.permissionRightIds.includes(p) ||
          item.permissionRightIds.includes(PERMISSION_RIGHT_CODE__createAsset) // TODO - pouze pro testovací účely - poté nutno smazat
        );
      })
    ) {
      items.push(item);
    }
  });

  return items;
};

export const getEntryUrlForProfile = (profile: ProfileType) => {
  const allowedUrls = getNavigationMenuItemsForProfile(profile);

  const state = store.getState();
  const entryLocation = state.shareEntity.entryLocation;

  //pokud je ve store uložena vstupní URL a uživatel má na tuto URL přístup
  if (entryLocation.pathname !== "") {
    const params = entryLocation.params;

    const entryUrl = [
      entryLocation.pathname,
      Object.entries(params)
        .filter(([key, value]) => {
          return key !== "a";
        })
        .map(([key, value]) => {
          return key + "=" + value;
        })
        .join("&"),
    ].join("?");

    for (let i = 0; i < allowedUrls.length; i++) {
      const allowedUrl = allowedUrls[i];
      if (allowedUrl.to === entryLocation.pathname) {
        return entryUrl;
      }
    }
  }

  //pokud má uživatel přístup na aktuální URL
  for (let i = 0; i < allowedUrls.length; i++) {
    const allowedUrl = allowedUrls[i];
    if (allowedUrl.to === window.location.pathname) {
      return allowedUrl.to;
    }
  }

  //pokud aktuální url je "/" nebo přihlašovací URL
  if (
    !!allowedUrls[0] &&
    ["", "/", ROUTER_PATH_LOGIN].includes(window.location.pathname)
  ) {
    return allowedUrls[0].to;
  }

  //jinak
  return window.location.pathname;
};
