import { Box, Grid, Paper } from "@mui/material";
import PageHeading from "../../../common/components/PageHeading/PageHeading";
import { Container } from "@mui/system";
import AssetList from "../AssetList/AssetList";
import { useAppDispatch } from "../../../../app/store";
import { useEffect } from "react";
import enumSlice from "../../../enum/slices/enumSlice";
import Search from "../Search/Search";
import AssetCreateButton from "../AssetCreateButton/AssetCreateButton";
import SearchViaQr from "../Search/SearchViaQr";
import K2Indicator from "../K2Indicator/K2Indicator";
import issueSlice from "../../../issue/slices/issueSlice";
import assetSlice from "../../slices/asset.slice";

const Asset = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(enumSlice.actions.enumsFetch());
    dispatch(assetSlice.actions.searchSubmit()); //po načtení komponenty okamžitě spouštím vyhledávání
  }, []);

  return (
    <Container maxWidth={false}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ mr: 2 }}>
              <PageHeading pageName="Zařízení" />
            </Box>
            <SearchViaQr />
          </Box>
        </Grid>

        <Grid
          container
          item
          xs={12}
          md={6}
          sx={{
            justifyContent: {
              md: "flex-end",
            },
            alignItems: {
              md: "center",
            },
          }}
        >
          <AssetCreateButton />
        </Grid>

        <Grid item xs={12}>
          <Paper
            sx={{ border: (theme) => `1px solid ${theme.palette.divider}` }}
            elevation={0}
          >
            {/*<Box sx={{ mt: 1, ml: 2, mr: 1, display: "flex" }}>*/}
            {/*  <Box sx={{ flexGrow: 1 }}>*/}
            {/*    <Search />*/}
            {/*  </Box>*/}
            {/*  <Box>*/}
            {/*    <K2Indicator />*/}
            {/*  </Box>*/}
            {/*</Box>*/}
            <AssetList />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Asset;
