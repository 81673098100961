import {
  selectSelectedIssueMode,
  selectSelectedIssueProperty,
} from "../../issue/selectors/issueSelectors";
import issueSlice from "../../issue/slices/issueSlice";
import { ToastTemplateType } from "../types/toast.types";
import { selectSelectedRevisionMode } from "../../revision/selectors/revisionSelectors";
import revisionSlice from "../../revision/slices/revisionSlice";
import { selectSelectedDocumentMode } from "../../document/selectors/document.selectors";
import documentSlice from "../../document/slices/document.slice";

const TOAST_TYPE_CODE__creating = "creating";
const TOAST_TYPE_CODE__creatingMultiple = "creatingMultiple";
const TOAST_TYPE_CODE__creatingRevision = "creatingRevision";
const TOAST_TYPE_CODE__deletingRevision = "deletingRevision";
const TOAST_TYPE_CODE__updating = "updating";
const TOAST_TYPE_CODE__commenting = "commenting";
const TOAST_TYPE_CODE__sendingToServiceCompany = "sendingToServiceCompany";
const TOAST_TYPE_CODE__sendingToServiceTechnician =
  "sendingToServiceTechnician";
const TOAST_TYPE_CODE__attachingFiles = "attachingFiles";
const TOAST_TYPE_CODE__markingAsDone = "markingAsDone";

const TOAST_TYPE_CODE__creatingDocument = "creatingDocument";
const TOAST_TYPE_CODE__deletingDocument = "deletingDocument";

const payloadHasAnyPropertyWithValue = (payload: any, properties: string[]) => {
  return properties.some((property) => {
    if (Array.isArray(payload)) {
      for (let i = 0; i < payload.length; i++) {
        if (payload[i].property === property && !!payload[i].value) {
          return true;
        }
      }
    }

    if (typeof payload === "object") {
      return payload.property === property && !!payload.value;
    }

    return false;
  });
};

export const toastTemplates: ToastTemplateType[] = [
  {
    code: TOAST_TYPE_CODE__creating,
    pending: {
      when: (action, listenerApi, { issue }) => {
        const mode = selectSelectedIssueMode(listenerApi.getState());
        const unitIds = selectSelectedIssueProperty(listenerApi.getState(), {
          property: "unitIds",
        });
        return (
          !!issue &&
          action.type === issueSlice.actions.selectedIssueSubmit.type &&
          mode === "create" &&
          !unitIds
        );
      },
      render: ({ issue }) => {
        return <>Vytváří se nový požadavek&hellip;</>;
      },
    },
    fulfilled: {
      render: ({ issue }) => {
        if (!issue) {
          return <></>;
        }
        return (
          <>
            Byl vytvořen nový požadavek <strong>#{issue.number}</strong>
          </>
        );
      },
    },
  },
  {
    code: TOAST_TYPE_CODE__creatingMultiple,
    pending: {
      when: (action, listenerApi, { issue }) => {
        const mode = selectSelectedIssueMode(listenerApi.getState());
        const unitIds = selectSelectedIssueProperty(listenerApi.getState(), {
          property: "unitIds",
        });
        return (
          !!issue &&
          action.type === issueSlice.actions.selectedIssueSubmit.type &&
          mode === "create" &&
          !!unitIds
        );
      },
      render: ({ count }) => {
        return <>Vytváří se {count} nové požadavky&hellip;</>;
      },
    },
    fulfilled: {
      render: ({ count }) => {
        return <>Byly vytvořeny {count} nové požadavky</>;
      },
    },
  },
  {
    code: TOAST_TYPE_CODE__creatingRevision,
    pending: {
      when: (action, listenerApi, { revision }) => {
        const mode = selectSelectedRevisionMode(listenerApi.getState());
        return (
          !!revision &&
          action.type === revisionSlice.actions.selectedRevisionSubmit.type &&
          mode === "create"
        );
      },
      render: ({ revision }) => {
        return <>Vytváří se nová revize&hellip;</>;
      },
    },
    fulfilled: {
      render: ({ revision }) => {
        if (!revision) {
          return <></>;
        }
        return <>Byla vytvořena nová revize.</>;
      },
    },
  },
  {
    code: TOAST_TYPE_CODE__updating,
    pending: {
      when: (action, listenerApi, { issue }) => {
        const mode = selectSelectedIssueMode(listenerApi.getState());
        return (
          !!issue &&
          action.type === issueSlice.actions.selectedIssueSubmit.type &&
          mode === "update"
        );
      },
      render: ({ issue }) => {
        return <>Upravuje se požadavek&hellip;</>;
      },
    },
    fulfilled: {
      render: ({ issue }) => {
        if (!issue) {
          return <></>;
        }
        return (
          <>
            Požadavek <strong>#{issue.number}</strong> byl upraven
          </>
        );
      },
    },
  },
  {
    code: TOAST_TYPE_CODE__commenting,
    pending: {
      when: (action, listenerApi, { issue }) => {
        const mode = selectSelectedIssueMode(listenerApi.getState());
        return (
          !!issue &&
          action.type === issueSlice.actions.selectedIssueCommentSubmit.type &&
          mode === "read"
        );
      },
      render: ({ issue }) => {
        return <>Přidává se komentář&hellip;</>;
      },
    },
    fulfilled: {
      render: ({ issue }) => {
        if (!issue) {
          return <></>;
        }
        return <>Komentář byl přidán</>;
      },
    },
  },
  {
    code: TOAST_TYPE_CODE__sendingToServiceCompany,
    pending: {
      when: (action, listenerApi, { issue }) => {
        const mode = selectSelectedIssueMode(listenerApi.getState());
        return (
          !!issue &&
          [
            issueSlice.actions.selectedIssuePropertySet.type,
            issueSlice.actions.selectedIssuePropertiesSet.type,
          ].includes(action.type) &&
          payloadHasAnyPropertyWithValue(action.payload, [
            "sentToServiceCompanyAt",
          ]) &&
          mode === "read"
        );
      },
      render: ({ issue }) => {
        return <>Odesílá se servisní firmě&hellip;</>;
      },
    },
    fulfilled: {
      render: ({ issue }) => {
        if (!issue) {
          return <></>;
        }
        return <>Požadavek byl odeslán servisní firmě</>;
      },
    },
  },
  {
    code: TOAST_TYPE_CODE__sendingToServiceTechnician,
    pending: {
      when: (action, listenerApi, issue) => {
        const mode = selectSelectedIssueMode(listenerApi.getState());
        return (
          !!issue &&
          [
            issueSlice.actions.selectedIssuePropertySet.type,
            issueSlice.actions.selectedIssuePropertiesSet.type,
          ].includes(action.type) &&
          payloadHasAnyPropertyWithValue(action.payload, [
            "sentToServiceTechnicianAt",
          ]) &&
          mode === "read"
        );
      },
      render: (issue) => {
        return <>Odesílá se servisnímu technikovi&hellip;</>;
      },
    },
    fulfilled: {
      render: (issue) => {
        if (!issue) {
          return <></>;
        }
        return <>Požadavek byl odeslán na servisního technika</>;
      },
    },
  },
  {
    code: TOAST_TYPE_CODE__markingAsDone,
    pending: {
      when: (action, listenerApi, { issue }) => {
        const mode = selectSelectedIssueMode(listenerApi.getState());
        return (
          !!issue &&
          [
            issueSlice.actions.selectedIssuePropertySet.type,
            issueSlice.actions.selectedIssuePropertiesSet.type,
          ].includes(action.type) &&
          payloadHasAnyPropertyWithValue(action.payload, [
            "markedAsDoneByUnitAt",
            "markedAsDoneByServiceCompanyAt",
            "markedAsDoneByHeadquartersAt",
          ]) &&
          mode === "read"
        );
      },
      render: ({ issue }) => {
        return <>Požadavek se označuje jako vyřízený&hellip;</>;
      },
    },
    fulfilled: {
      render: ({ issue }) => {
        if (!issue) {
          return <></>;
        }
        return (
          <>
            Požadavek <strong>#{issue.number}</strong> byl označen jako vyřízený
          </>
        );
      },
    },
  },
  {
    code: TOAST_TYPE_CODE__creatingDocument,
    pending: {
      when: (action, listenerApi, { document }) => {
        const mode = selectSelectedDocumentMode(listenerApi.getState());
        return (
          !!document &&
          action.type === documentSlice.actions.selectedDocumentSubmit.type &&
          mode === "create"
        );
      },
      render: ({ document }) => {
        return <>Ukládám dokument&hellip;</>;
      },
    },
    fulfilled: {
      render: ({ document }) => {
        return <>Dokument byl uložen.</>;
      },
    },
  },
  {
    code: TOAST_TYPE_CODE__deletingDocument,
    pending: {
      when: (action, listenerApi, { document }) => {
        return (
          !!document &&
          action.type === documentSlice.actions.documentDelete.type
        );
      },
      render: ({ document }) => {
        return <>Odstraňuji dokument&hellip;</>;
      },
    },
    fulfilled: {
      render: ({ document }) => {
        return <>Dokument byl odstraněn.</>;
      },
    },
  },
  {
    code: TOAST_TYPE_CODE__deletingRevision,
    pending: {
      when: (action, listenerApi, { revision }) => {
        return (
          !!revision &&
          action.type === revisionSlice.actions.revisionDelete.type
        );
      },
      render: ({ document }) => {
        return <>Odstraňuji revizi&hellip;</>;
      },
    },
    fulfilled: {
      render: ({ document }) => {
        return <>Revize byla odstraněna.</>;
      },
    },
  },
];
