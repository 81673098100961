import { useAppSelector } from "../../../../app/store";
import { selectRevisionById } from "../../selectors/revisionSelectors";
import { Box, Button, ButtonGroup } from "@mui/material";
import React from "react";
import RevisionActionUpgrade from "../Action/RevisionActionUpgrade";
import RevisionActionDelete from "../Action/RevisionActionDelete";
import { AddCircle, Delete, Upgrade } from "@mui/icons-material";
import RevisionActionCreateIssue from "../Action/RevisionActionCreateIssue";

const FieldAction = (props: any) => {
  const { revisionId, isDetail = false } = props;

  const revision = useAppSelector((state) =>
    selectRevisionById(state, revisionId)
  );

  if (!revision) {
    return <></>;
  }

  return (
    <>
      <Box sx={{ p: 2, width: "100%", textAlign: { md: "right" } }}>
        <ButtonGroup variant="outlined" orientation="vertical">
          <RevisionActionUpgrade revision={revision}>
            <Button size={"small"} startIcon={<Upgrade />}>
              Nová revize
            </Button>
          </RevisionActionUpgrade>
          <RevisionActionDelete revision={revision}>
            <Button size={"small"} startIcon={<Delete />} color={"error"}>
              Odstranit
            </Button>
          </RevisionActionDelete>
          <RevisionActionCreateIssue revision={revision}>
            <Button size={"small"} startIcon={<AddCircle />} color={"primary"}>
              Požadavek
            </Button>
          </RevisionActionCreateIssue>
        </ButtonGroup>
      </Box>
    </>
  );
};

export const fieldAction = {
  code: "fieldAction",
  label: "",
  component: FieldAction,
};
