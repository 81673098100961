import { assetEntityAdapter } from "../slices/asset.slice";
import { RootState } from "../../../app/store";
import { createSelector } from "@reduxjs/toolkit";
import { selectUnitEntities } from "../../enum/selectors/enumSelectors";
import { selectProfile } from "../../auth/selectors/authSelectors";
import { AssetFilterType } from "../../../types/asset.types";

const assetListSelector = assetEntityAdapter.getSelectors(
  (state: RootState) => state.asset.list
);

export const selectAssetIds = assetListSelector.selectIds;
export const selectAssetEntities = assetListSelector.selectEntities;
export const selectAssetsAll = assetListSelector.selectAll;
export const selectAssetById = assetListSelector.selectById;

export const selectListFetching = (state: RootState) =>
  state.asset.listFetching;
export const selectListFetchingStatus = (state: RootState) =>
  state.asset.listFetchingStatus;

export const selectSelectedAsset = (state: RootState) =>
  state.asset.selectedAsset;
export const selectHasSelectedAsset = (state: RootState) =>
  !!state.asset.selectedAsset;
export const selectSelectedAssetMode = (state: RootState) =>
  state.asset.selectedAssetMode;

export const selectSelectedAssetProperty = createSelector(
  [selectSelectedAsset, (state, args) => args.property],
  (asset, property) => {
    if (!asset) {
      return null;
    }

    // @ts-ignore
    return asset[property];
  }
);

export const selectAssetFilter = (state: RootState): AssetFilterType =>
  state.asset.filter;

export const selectAssetSearch = (state: RootState): AssetFilterType =>
  state.asset.search;

export const selectAssetSearchProperty = createSelector(
  [selectAssetSearch, (state, args) => args.property],
  (search, property) => {
    if (!search) {
      return null;
    }

    // @ts-ignore
    return search[property];
  }
);

export const selectAssetsSearchedAndSorted = createSelector(
  [
    selectAssetsAll,
    selectAssetSearch,
    selectAssetFilter,
    selectUnitEntities,
    selectProfile,
  ],
  (assets, search, filter, unitEntities, profile) => {
    if (!profile) {
      return [];
    }

    return assets
      .filter((a) => {
        const qrCodes = a.qrCodes.map((q) => q.content);
        return filter.qrCode === "" || qrCodes.includes(filter.qrCode);
      })
      .sort((a, b) => {
        return a.unitId.localeCompare(b.unitId);
      });
  }
);

export const selectAssetsSearchedAndSortedCount = createSelector(
  [selectAssetsSearchedAndSorted],
  (assets) => {
    return assets.length;
  }
);

export const selectListConfig = (state: RootState) => {
  return state.asset.listConfig;
};

export const selectAssetIdsSearchedAndSorted = createSelector(
  [selectAssetsSearchedAndSorted, selectListConfig],
  (assets, listConfig) => {
    return assets
      .map((asset) => {
        return asset.id;
      })
      .slice(
        listConfig.page * listConfig.pageSize,
        (listConfig.page + 1) * listConfig.pageSize
      );
  }
);
