import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { selectSelectedIssueMode } from "../../../selectors/issueSelectors";
import issueSlice from "../../../slices/issueSlice";
import { useDropzone } from "react-dropzone";
import { Box, Button } from "@mui/material";
import { IssueAttachmentInputType } from "../../../../../types";
import {
  ISSUE_ATTACHMENT_TYPE_CODE__attachment,
  ISSUE_ATTACHMENT_TYPE_CODE__serviceList,
  issueAttachmentTypeCodeType,
} from "../../../../../libraries/enums/issueAttachmentTypes";
import { FileUpload } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { grey } from "@mui/material/colors";

const attachmentType2AcceptMimeType = {
  [ISSUE_ATTACHMENT_TYPE_CODE__attachment]: {
    "image/png": [".png"],
    "image/jpeg": [".jpg", ".jpeg"],
  },
  [ISSUE_ATTACHMENT_TYPE_CODE__serviceList]: {
    "image/png": [".png"],
    "image/jpeg": [".jpg", ".jpeg"],
    "application/pdf": [".pdf"],
  },
};
const attachmentType2Label = {
  [ISSUE_ATTACHMENT_TYPE_CODE__attachment]: "fotografie",
  [ISSUE_ATTACHMENT_TYPE_CODE__serviceList]: "servisní listy",
};

type FileEnvelopeType = {
  name: string;
  file: File;
  preview: string | undefined;
};

const toBase64 = (file: File): any =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const IssueDetailFieldAttachmentsEditable = (props: {
  type: issueAttachmentTypeCodeType;
}) => {
  const { type } = props;

  const dispatch = useAppDispatch();

  const [files, setFiles] = useState<FileEnvelopeType[]>([]);

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    accept: attachmentType2AcceptMimeType[type],
    onDrop: (acceptedFiles) => {
      setFiles((prev) => {
        return prev.concat(
          acceptedFiles
            .filter((acceptedFile) => {
              return !prev.map((p) => p.name).includes(acceptedFile.name);
            })
            .map((acceptedFile) => {
              return {
                name: acceptedFile.name,
                file: acceptedFile,
                preview: URL.createObjectURL(acceptedFile),
              };
            })
        );
      });
    },
  });

  const removeFile = (fileName: string) => {
    setFiles((prev) => {
      const index = prev.findIndex(
        (p: FileEnvelopeType) => p.name === fileName
      );
      if (index > -1) {
        return [...prev.slice(0, index), ...prev.slice(index + 1)];
      }
      return prev;
    });
  };

  const changeValue = (value: any) => {
    dispatch(
      issueSlice.actions.selectedIssuePropertySet({
        property: "attachmentInputs",
        value: value,
      })
    );
  };

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      files.forEach((file: any) => URL.revokeObjectURL(file.preview));
  }, []);

  useEffect(() => {
    const update = async (files: FileEnvelopeType[]) => {
      const value: IssueAttachmentInputType[] = [];
      for (const fe of files) {
        const base64 = await toBase64(fe.file);
        value.push({
          name: fe.file.name,
          base64: base64,
          type: type,
          mimeType: fe.file.type,
        });
      }
      changeValue(value);
    };

    update(files);
  }, [files]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {files.map((file: FileEnvelopeType) => {
          return (
            <Box
              key={file.name}
              sx={{
                mb: 1,
                mr: 1,
                bgcolor: grey[100],
                p: 1,
                borderRadius: "4px",
              }}
            >
              <Box
                sx={{
                  display: "inline-flex",
                  borderRadius: "4px",
                  width: 114,
                  height: 114,
                  boxSizing: "border-box",
                }}
              >
                <Box sx={{}}>
                  {file.file.type.startsWith("image") ? (
                    <>
                      <img
                        alt={file.name}
                        style={{
                          display: "block",
                          width: "100%",
                          height: "100%",
                          objectPosition: "center",
                          objectFit: "cover",
                          borderRadius: 4,
                        }}
                        src={file.preview}
                        // Revoke data uri after image is loaded
                        onLoad={() => {
                          if (file.preview != null) {
                            URL.revokeObjectURL(file.preview);
                          }
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <Box>{file.name}</Box>
                    </>
                  )}
                </Box>
              </Box>
              <Box>
                <Button
                  onClick={() => {
                    removeFile(file.name);
                  }}
                  size={"small"}
                  color={"error"}
                  variant="text"
                  startIcon={<DeleteIcon />}
                  sx={{ mt: 1 }}
                  fullWidth
                >
                  Odstranit
                </Button>
              </Box>
            </Box>
          );
        })}
      </Box>
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <Button
          color={"secondary"}
          size={"small"}
          variant={"outlined"}
          onClick={open}
          startIcon={<FileUpload />}
        >
          Nahrát {files.length > 0 && <>další </>} {attachmentType2Label[type]}
        </Button>
      </div>
    </>
  );
};

const IssueDetailFieldAttachments = (props: {
  type: issueAttachmentTypeCodeType;
}) => {
  const mode = useAppSelector(selectSelectedIssueMode);

  switch (mode) {
    case "create": {
      return <IssueDetailFieldAttachmentsEditable {...props} />;
    }
    case "update": {
      return <IssueDetailFieldAttachmentsEditable {...props} />;
    }
    case "read": {
      return <IssueDetailFieldAttachmentsEditable {...props} />;
    }
    default: {
      return <></>;
    }
  }
};

export default IssueDetailFieldAttachments;
