import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import {
  selectMarkingAsDoneConfirmDialog,
  selectSelectedIssue,
} from "../../selectors/issueSelectors";
import issueSlice from "../../slices/issueSlice";
import { CheckCircle } from "@mui/icons-material";
import { ISSUE_ATTACHMENT_TYPE_CODE__serviceList } from "../../../../libraries/enums/issueAttachmentTypes";
import IssueDetailFieldAttachments from "../IssueDetail/IssueDetailField/IssueDetailFieldAttachments";
import React from "react";
import CloseButton from "../../../common/components/CloseButton/CloseButton";

const MarkAsDoneConfirmDialog = () => {
  const dispatch = useAppDispatch();
  const dialog = useAppSelector(selectMarkingAsDoneConfirmDialog);
  const issue = useAppSelector(selectSelectedIssue);

  const handleClose = () => {
    dispatch(
      issueSlice.actions.markingAsDoneConfirmDialogSet({
        open: false,
        actionType: null,
      })
    );
  };

  const handleConfirm = () => {
    dispatch(
      issueSlice.actions.selectedIssuePropertiesSet([
        {
          property: dialog.actionType,
          value: new Date().toISOString(),
        },
      ])
    );
    handleClose();
  };

  const label =
    issue?.attachmentInputs?.length === 0
      ? "Označit jako vyřízený bez servisního listu"
      : "Označit jako vyřízené";

  return (
    <Dialog
      open={dialog.open}
      onClose={() => {
        handleClose();
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        Není nahraný servisní list
        <CloseButton onClick={handleClose} />
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Alert severity="error" sx={{ mb: 2 }}>
          Požadavek, který označujete jako vyřízený, nemá nahraný žádný servisní
          list. Před uzavřením požadavku prosím servisní list nahrajte.
        </Alert>
        <IssueDetailFieldAttachments
          type={ISSUE_ATTACHMENT_TYPE_CODE__serviceList}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          color={"primary"}
          variant={"contained"}
          startIcon={<CheckCircle />}
          onClick={() => {
            handleConfirm();
          }}
        >
          {label}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MarkAsDoneConfirmDialog;
