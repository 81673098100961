import { documentEntityAdapter } from "../slices/document.slice";
import { RootState } from "../../../app/store";
import { createSelector } from "@reduxjs/toolkit";
import { DocumentFilterType } from "../../../types";
import {
  selectSelectedUnitId,
  selectUnitEntities,
} from "../../enum/selectors/enumSelectors";
import { selectProfile } from "../../auth/selectors/authSelectors";
import { PERMISSION_RIGHT_CODE__createDocument } from "../../../libraries/enums/permissionRights";

const documentListSelector = documentEntityAdapter.getSelectors(
  (state: RootState) => state.document.list
);

export const selectDocumentIds = documentListSelector.selectIds;
export const selectDocumentEntities = documentListSelector.selectEntities;
export const selectDocumentsAll = documentListSelector.selectAll;
export const selectDocumentById = documentListSelector.selectById;

export const selectListFetching = (state: RootState) =>
  state.document.listFetching;

export const selectSelectedDocument = (state: RootState) =>
  state.document.selectedDocument;
export const selectHasSelectedDocument = (state: RootState) =>
  !!state.document.selectedDocument;
export const selectSelectedDocumentMode = (state: RootState) =>
  state.document.selectedDocumentMode;

export const selectSelectedDocumentProperty = createSelector(
  [selectSelectedDocument, (state, args) => args.property],
  (document, property) => {
    if (!document) {
      return null;
    }

    // @ts-ignore
    return document[property];
  }
);

/**
 * Returns true if filter is enabled
 * @param state
 */
export const selectDocumentFilter = (state: RootState): DocumentFilterType =>
  state.document.filter;
export const selectDocumentFilterIsEnabled = createSelector(
  [selectDocumentFilter],
  (f) => {
    return false;
  }
);
export const selectDocumentFilterProperty = createSelector(
  [selectDocumentFilter, (state, args) => args.property],
  (filter, property) => {
    if (!filter) {
      return null;
    }

    // @ts-ignore
    return filter[property];
  }
);

export const selectDocumentsFilteredAndSorted = createSelector(
  [
    selectDocumentsAll,
    selectDocumentFilter,
    selectUnitEntities,
    selectProfile,
    selectSelectedUnitId,
  ],
  (documents, filter, unitEntities, profile, selectedUnitId) => {
    if (!profile) {
      return [];
    }
    const canCreateDocument = profile.permission.permissionRightIds.includes(
      PERMISSION_RIGHT_CODE__createDocument
    );

    return documents.filter(
      (d) =>
        d.unitId === selectedUnitId &&
        ((d.type === "document" && !!d.path) ||
          (d.type === "documentTemplate" && canCreateDocument))
    );
  }
);

export const selectDocumentsFilteredAndSortedCount = createSelector(
  [selectDocumentsFilteredAndSorted],
  (documents) => {
    return documents.length;
  }
);

export const selectDocumentsForUnitId = createSelector(
  [selectDocumentsAll, (state, args) => args.unitId],
  (documents, unitId) => {
    return documents.filter((d) => d.unitId === unitId);
  }
);

export const selectListConfig = (state: RootState) => {
  return state.document.listConfig;
};

export const selectDocumentIdsFilteredAndSorted = createSelector(
  [selectDocumentsFilteredAndSorted, selectListConfig],
  (documents, listConfig) => {
    return documents
      .map((document) => {
        // @ts-ignore
        return document.id;
      })
      .slice(
        listConfig.page * listConfig.pageSize,
        (listConfig.page + 1) * listConfig.pageSize
      );
  }
);
