import { AnyAction } from "@reduxjs/toolkit";
import issueSlice from "../slices/issueSlice";
import { RootState } from "../../../app/store";
import {
  selectSelectedIssue,
  selectSelectedIssueMode,
} from "../selectors/issueSelectors";
import {
  effectIssueListFetchArgsType,
  ListenerApiType,
} from "../../../types/api.types";
import {
  enumGetAll,
  issueCommentPost,
  issueGetAll,
  issuePost,
  notificationIssueCommentSendPost,
  revisionGetOne,
} from "../../../api/api";
import { effectEnumsFetch_serviceTechnician } from "../../enum/middlewares/enum.effect";
import enumSlice from "../../enum/slices/enumSlice";
import revisionSlice from "../../revision/slices/revisionSlice";

export const effectIssueListFetch = async (
  action: AnyAction,
  listenerApi: ListenerApiType,
  args: effectIssueListFetchArgsType = {
    showFetchingIndicator: true,
    resetList: false,
    queryParams: {},
  }
) => {
  if (args.showFetchingIndicator) {
    listenerApi.dispatch(issueSlice.actions.issueListFetching(true));
  }

  issueGetAll({ queryParams: args.queryParams })
    .then((response: any) => {
      if (response.status === 200) {
        if (args.resetList) {
          listenerApi.dispatch(
            issueSlice.actions.issueListSet(response.data.issues)
          );
        } else {
          listenerApi.dispatch(
            issueSlice.actions.issueListUpsertMany(response.data.issues)
          );
        }
      }
    })
    .finally(() => {
      listenerApi.dispatch(issueSlice.actions.issueListFetching(false));
    });
};

export const effectSelectedIssueSubmit = async (
  action: AnyAction,
  listenerApi: ListenerApiType
) => {
  const originalState: RootState = listenerApi.getState() as RootState;
  const issue = selectSelectedIssue(originalState);
  const mode = selectSelectedIssueMode(originalState);

  if (!!issue) {
    issuePost({ data: { issue } }).then((response: any) => {
      if (response.status === 200) {
        if (response.data.length === 1) {
          listenerApi.dispatch(
            issueSlice.actions.issueListUpsertOne(response.data[0])
          );
        } else {
          listenerApi.dispatch(
            issueSlice.actions.issueListUpsertMany(response.data)
          );
        }
        if (mode === "update") {
          listenerApi.dispatch(
            issueSlice.actions.selectedIssueSet(response.data[0])
          );
        }
        effectEnumsFetch_serviceTechnician(action, listenerApi);

        //pokud je požadavek označen jako vyřízený
        if (
          issueSlice.actions.selectedIssuePropertiesSet.type === action.type &&
          [
            "markedAsDoneByUnitAt",
            "markedAsDoneByServiceTechnicianAt",
            "markedAsDoneByServiceCompanyAt",
            "markedAsDoneByHeadquartersAt",
          ].includes(action.payload[0].property)
        ) {
          //není potřeba nadále nechat otevřené okno s detailem požadavku
          //vyšle se dispatch s pokynem na zavření dialog okna
          //na tento dispatch je nasloucháno IssueDetail.tsx
          listenerApi.dispatch(
            issueSlice.actions.selectedIssueClosingDialogSet(true)
          );
        }

        //pokud požadavek vznikl na základě revize
        if (!!issue.createdByRevisionId) {
          revisionGetOne({ revisionId: issue.createdByRevisionId }).then(
            (response) => {
              if (response.status === 200) {
                // @ts-ignore
                const revision = response.data;
                listenerApi.dispatch(
                  revisionSlice.actions.revisionListUpsertOne(revision)
                );
              }
            }
          );
        }
      }
    });
  }
};

export const effectSelectIssueCommentSubmit = async (
  action: AnyAction,
  listenerApi: ListenerApiType
) => {
  const originalState: RootState = listenerApi.getState() as RootState;
  const issue = selectSelectedIssue(originalState);
  const mode = selectSelectedIssueMode(originalState);

  if (!!issue) {
    issueCommentPost({ issue, data: { message: action.payload.message } }).then(
      (response: any) => {
        if (response.status === 200) {
          listenerApi.dispatch(
            issueSlice.actions.issueListUpsertOne(response.data)
          );

          //api call to send comment notification
          const comments = [...response.data.comments];
          comments.sort((a: string, b: string) => {
            // @ts-ignore
            return new Date(a.createdAt) - new Date(b.createdAt);
          });
          const lastComment = comments[comments.length - 1];
          notificationIssueCommentSendPost({
            data: { issueComment: { id: lastComment.id } },
          });
          ////

          if (mode === "update") {
            listenerApi.dispatch(
              issueSlice.actions.selectedIssueSet(response.data)
            );
          }
        }
      }
    );
  }
};
