import React, { useState } from "react";
import { UnitType } from "../../../../types";
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Switch,
} from "@mui/material";
import RevisionCreateButton from "../../../revision/components/RevisionCreateButton/RevisionCreateButton";
import RevisionTemplateList from "../../../revisionTemplate/components/RevisionTemplateList/RevisionTemplateList";
import {
  REVISION_STATUS_CODE__invalidDueToExpiration,
  REVISION_STATUS_CODE__invalidDueToReplacement,
  REVISION_STATUS_CODE__valid,
} from "../../../../libraries/enums/revisionStatuses";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { selectRevisionFilterProperty } from "../../../revision/selectors/revisionSelectors";
import revisionSlice from "../../../revision/slices/revisionSlice";
import {
  getRevisionExpirationStatusByCode,
  REVISION_EXPIRATION_STATUS_after,
  REVISION_EXPIRATION_STATUS_expiring,
  REVISION_EXPIRATION_STATUS_replaced,
} from "../../../../libraries/enums/revisionExpirationStatuses";

const TabRevisionFilterExpirationStatus = (props: any) => {
  const { value, setValue } = props;

  const items = [
    {
      code: REVISION_STATUS_CODE__invalidDueToReplacement,
      label: "Zobrazit i nahrazené revize",
    },
  ];

  return (
    <FormControl component="fieldset" margin="none" fullWidth size={"small"}>
      <FormGroup row>
        {items.map((item) => {
          const checked = value.includes(item.code);
          return (
            <FormControlLabel
              key={item.code}
              control={
                <Checkbox
                  size={"small"}
                  checked={checked}
                  onChange={() => {
                    if (value.includes(item.code)) {
                      setValue(value.filter((v: any) => v !== item.code));
                    } else {
                      setValue(value.concat(item.code));
                    }
                  }}
                />
              }
              label={item.label}
              labelPlacement="end"
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
};

const TabRevisions = (props: { unit: UnitType }) => {
  const [value, setValue] = useState([]);

  return (
    <>
      <Grid container mb={2} alignItems={"center"}>
        <Grid item xs={6}>
          <TabRevisionFilterExpirationStatus
            value={value}
            setValue={setValue}
          />
        </Grid>
        <Grid item xs={6} textAlign={"right"}>
          <RevisionCreateButton />
        </Grid>
      </Grid>
      <Box>
        <RevisionTemplateList unitId={props.unit.id} statusIds={value} />
      </Box>
    </>
  );
};

export default TabRevisions;
