import { DocumentType } from "../../../types";
import { v4 as uuidv4 } from "uuid";

export const buildDocument = () => {
  const document: DocumentType = {
    documentId: null,
    id: uuidv4(),
    note: "",
    path: null,
    title: "",
    unitId: null,
    type: "document",
    fileContent: "",
    templateId: null,
    isNotNecessary: false,
  };

  return document;
};
