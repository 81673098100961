import { selectRevisionSubjects } from "../../enum/selectors/enumSelectors";
import { createSelector } from "@reduxjs/toolkit";
import { selectRevisionsAll } from "../../revision/selectors/revisionSelectors";
import {
  REVISION_STATUS_CODE__invalidDueToExpiration,
  REVISION_STATUS_CODE__valid,
} from "../../../libraries/enums/revisionStatuses";
import { RevisionTemplateType } from "../../../types";
import { selectProfilePermission } from "../../common/selectors/commonSelectors";
import { PERMISSION_RIGHT_CODE__createRevision } from "../../../libraries/enums/permissionRights";

export const selectRevisionTemplates = createSelector(
  [
    selectRevisionSubjects,
    selectRevisionsAll,
    (state, args) => args.unitId,
    selectProfilePermission,
  ],
  (revisionSubjects, revisions, unitId, permission) => {
    const templates: RevisionTemplateType[] = [];
    revisionSubjects.forEach((revisionSubject) => {
      const revs = revisions
        .filter((r) => {
          return (
            r.revisionSubjectId === revisionSubject.id && r.unitId === unitId
          );
        })
        .sort((a, b) => {
          return a.revisionAt.localeCompare(b.revisionAt);
        });
      if (revs.length > 0) {
        revs.forEach((r) => {
          templates.push({ revisionSubject, revision: r });
        });
      } else {
        if (
          permission.permissionRightIds.includes(
            PERMISSION_RIGHT_CODE__createRevision
          )
        ) {
          templates.push({ revisionSubject });
        }
      }
    });

    return templates;
  }
);
