import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import React, { useEffect, useState } from "react";
import CloseButton from "../../../common/components/CloseButton/CloseButton";
import { useTheme } from "@mui/material/styles";
import {
  selectHasSelectedAsset,
  selectSelectedAsset,
  selectSelectedAssetMode,
} from "../../selectors/asset.selector";
import assetSlice from "../../slices/asset.slice";
import { grey } from "@mui/material/colors";
import { BrandType, ProfileType } from "../../../../types";
import { selectProfile } from "../../../auth/selectors/authSelectors";
import { getBrandLogoUrlByBrandCode } from "../../../../libraries/brands";
import {
  selectBrandById,
  selectUnitById,
} from "../../../enum/selectors/enumSelectors";
import { AssetType } from "../../../../types/asset.types";
import { EntityId } from "@reduxjs/toolkit";
import { fieldAsset, FieldAssetContent } from "../AssetList/fields/fieldAsset";

const getTabItems = (profile: ProfileType) => {
  const INFO = { value: "info", label: "Základní informace" };
  const K2 = { value: "k2", label: "K2" };
  const ISSUES = { value: "issues", label: "Servisní požadavky" };
  const REVISIONS = { value: "revisions", label: "Revize a kontroly" };
  const DOCUMENTS = { value: "documents", label: "Dokumenty" };
  const HISTORY = { value: "history", label: "Historie" };

  const items = [INFO, K2, ISSUES, REVISIONS, DOCUMENTS, HISTORY];

  // if (
  //   profile.permission.permissionRightIds.some((p) => {
  //     return [
  //       PERMISSION_RIGHT_CODE__createRevision,
  //       PERMISSION_RIGHT_CODE__readRevision,
  //     ].includes(p);
  //   })
  // ) {
  //   assets.push(REVISIONS);
  // }
  //
  // if (
  //   profile.permission.permissionRightIds.some((p) => {
  //     return [
  //       PERMISSION_RIGHT_CODE__createDocument,
  //       PERMISSION_RIGHT_CODE__readDocument,
  //     ].includes(p);
  //   })
  // ) {
  //   assets.push(DOCUMENTS);
  // }

  return items;
};

const AssetDetailHeaderUnit = ({ asset }: { asset: AssetType }) => {
  const unit = useAppSelector((state) => selectUnitById(state, asset.unitId));
  const brand = useAppSelector((state) =>
    !unit || !unit.brandId
      ? null
      : selectBrandById(state, unit.brandId as unknown as EntityId)
  );
  if (!unit || !brand) {
    return <></>;
  }

  return (
    <>
      <Avatar
        src={getBrandLogoUrlByBrandCode(
          brand.code as unknown as Pick<BrandType, "code">
        )}
        sx={{ mr: 1 }}
      />
      <Box>
        <Typography variant="body2" sx={{ color: grey[600], fontWeight: 600 }}>
          {unit.title}
        </Typography>
        <Typography variant="body2" sx={{ whiteSpace: "nowrap" }}>
          {unit.subtitle || ""}
        </Typography>
      </Box>
    </>
  );
};

const AssetDetailField = (props: any) => {
  const { field, fieldProps, children, onlyChildren = false } = props;

  return (
    <Card sx={{ mb: 2 }} variant="outlined">
      <CardHeader
        title={field.label}
        titleTypographyProps={{
          sx: {
            fontSize: 15,
            fontWeight: 500,
          },
        }}
        sx={{ bgcolor: grey[100] }}
      />
      <Divider />
      <CardContent
        sx={{
          p: 0,
          "&:last-child": {
            p: 0,
          },
        }}
      >
        {!onlyChildren && React.createElement(field.component, fieldProps)}
        {!!children && children}
      </CardContent>
    </Card>
  );
};

const AssetDetail = () => {
  const dispatch = useAppDispatch();

  const theme = useTheme();

  const asset = useAppSelector(selectSelectedAsset);
  const profile = useAppSelector(selectProfile);
  const hasAsset = useAppSelector(selectHasSelectedAsset);
  //const isClosing = useAppSelector(selectSelectedIssueClosingDialog);
  const isClosing = false;
  const mode = useAppSelector(selectSelectedAssetMode);
  // const unitIds = useAppSelector((state) =>
  //   selectSelectedIssueProperty(state, { property: "unitIds" })
  // );

  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState("info");
  const [errors, setErrors] = useState<any>([]);

  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  const validate = () => {
    return [];
    // const errors = [];
    // const state = store.getState();
    // const asset = selectSelectedAsset(state);
    // if (!!asset) {
    //   // if (!asset.unitId && !asset.unitIds) {
    //   //   errors.push("Středisko musí být vyplněno.");
    //   // }
    //   // if (!asset.description) {
    //   //   errors.push("Pole Požadavek musí být vyplněno.");
    //   // }
    // }
    //
    // return errors;
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setErrors([]);
      dispatch(assetSlice.actions.selectedAssetSet(null));
      dispatch(assetSlice.actions.selectedAssetModeSet(null));
      //dispatch(assetSlice.actions.selectedIssueClosingDialogSet(false));
    }, 250);
  };

  const handleSubmit = () => {
    const errors = validate();
    if (errors.length === 0) {
      //dispatch(assetSlice.actions.selectedIssueSubmit());
      if (mode === "update") {
        dispatch(assetSlice.actions.selectedAssetModeSet("read"));
      } else {
        handleClose();
      }
    } else {
      setErrors(errors);
    }
  };

  useEffect(() => {
    if (hasAsset) {
      setErrors([]);
      setOpen(true);
      setTab("info");
    }
  }, [hasAsset]);

  useEffect(() => {
    if (isClosing) {
      handleClose();
    }
  }, [isClosing]);

  if (!asset || !profile) {
    return <></>;
  }

  if (mode === "create" || mode === "update") {
    return (
      <Dialog
        open={open}
        onClose={() => {
          handleClose();
        }}
        fullWidth
        maxWidth="sm"
        fullScreen={mdUp ? false : true}
      >
        <DialogTitle>
          {mode === "create" && <>Přidat zařízení</>}
          {mode === "update" && <>Editovat zařízení</>}
          <CloseButton onClick={handleClose} />
        </DialogTitle>
        <Divider />
        <DialogContent>
          {/*<IssueDetailModeUpdate handleSubmit={handleSubmit} />*/}
          {/*{errors.length > 0 && (*/}
          {/*  <Alert severity="error" sx={{ mb: 2 }}>*/}
          {/*    {errors.join(" ")}*/}
          {/*  </Alert>*/}
          {/*)}*/}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            variant={"contained"}
            color={"primary"}
            startIcon={<DoneIcon />}
            onClick={() => {
              handleSubmit();
            }}
          >
            {mode === "create" && <>Vytvořit zařízení</>}
            {mode === "update" && <>Uložit zařízení</>}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      fullWidth
      maxWidth="lg"
      fullScreen={mdUp ? false : true}
    >
      <DialogTitle sx={{ p: 0 }}>
        <Box
          sx={{
            m: 2,
            display: "flex",
            alignAssets: "center",
          }}
        >
          <Box display="flex" alignItems="center">
            <AssetDetailHeaderUnit asset={asset} />
            <Box sx={{ ml: 2 }}>
              <FieldAssetContent asset={asset} />
            </Box>
          </Box>
          <CloseButton onClick={handleClose} />
        </Box>
        <Divider />
        {getTabItems(profile).length > 1 && (
          <Tabs
            textColor={"secondary"}
            indicatorColor={"secondary"}
            value={tab}
            onChange={(event, newValue) => {
              setTab(newValue);
            }}
          >
            {getTabItems(profile).map((item) => {
              return (
                <Tab key={item.value} label={item.label} value={item.value} />
              );
            })}
          </Tabs>
        )}
      </DialogTitle>
      <DialogContent dividers={true}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <AssetDetailField field={fieldAsset} />
          </Grid>
          <Grid item xs={12} md={6}></Grid>
        </Grid>
        {/*<IssueDetailModeRead />*/}
      </DialogContent>
      {/*<DialogActions sx={{ justifyContent: "flex-start" }}>*/}
      {/*  <Button*/}
      {/*    variant="outlined"*/}
      {/*    size="small"*/}
      {/*    startIcon={<ContentCopy />}*/}
      {/*    onClick={() => {*/}
      {/*      window.navigator.clipboard.writeText(window.location.href);*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    Zkopírovat do schránky*/}
      {/*  </Button>*/}
      {/*</DialogActions>*/}
    </Dialog>
  );
};

export default AssetDetail;
