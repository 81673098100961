import React from "react";
import {
  AppBar,
  Box,
  Hidden,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { selectProfile } from "../../../auth/selectors/authSelectors";
import { Nullable, ProfileType } from "../../../../types";
import CssBaseline from "@mui/material/CssBaseline";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Footer from "../Footer/Footer";
import { logOut } from "../../../auth/utils/utils";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getUserTypeByCode } from "../../../../libraries/enums/userTypes";
import HeadwayWidget from "@querycz/headway-react-widget";
import { getNavigationMenuItemsForProfile } from "../../../../app/navigation.utils";

const LayoutAppBarMenu = () => {
  const location = useLocation();
  const profile = useAppSelector(selectProfile);

  const resolvePathname = (pathname: string) => {
    if (["/", "/dashboard", "/issues"].includes(pathname)) {
      return "/issues";
    }
    if (["/revisions"].includes(pathname)) {
      return "/revisions";
    }
    if (["/documents"].includes(pathname)) {
      return "/documents";
    }
    if (["/assets"].includes(pathname)) {
      return "/assets";
    }
  };

  if (!profile) {
    return <></>;
  }

  return (
    <Tabs
      sx={{ flexGrow: 1 }}
      value={resolvePathname(location.pathname)}
      textColor={"secondary"}
      indicatorColor={"secondary"}
      scrollButtons="auto"
      variant="scrollable"
    >
      {getNavigationMenuItemsForProfile(profile).map((item) => {
        return (
          <Tab
            key={item.to}
            value={item.to}
            label={item.label}
            component={Link}
            to={item.to}
            sx={{
              pt: 3.1,
              pb: 2.8,
            }}
          />
        );
      })}
    </Tabs>
  );
};

type LayoutAppBarProfileProps = {
  profile: Nullable<ProfileType>;
};
const LayoutAppBarProfile = (props: LayoutAppBarProfileProps) => {
  const { profile } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    logOut(dispatch, navigate);
  };

  if (!profile) {
    return <></>;
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Hidden smDown>
        <Avatar
          sx={{ backgroundColor: (theme) => `${theme.palette.secondary.main}` }}
        >
          {React.createElement(getUserTypeByCode(profile.type).icon)}
        </Avatar>

        <Typography variant="subtitle2" sx={{ ml: 1, mr: 2 }}>
          {profile.displayName}
        </Typography>
      </Hidden>

      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            handleLogOut();
          }}
        >
          <ListItemIcon>
            <ExitToAppIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Odhlásit se" />
        </MenuItem>
      </Menu>
    </Box>
  );
};

const LayoutAppBar = () => {
  const profile: Nullable<ProfileType> = useAppSelector(selectProfile);

  return (
    <>
      <CssBaseline />
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          backgroundColor: (theme) => `${theme.palette.common.white}`,
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          paddingTop: "env(safe-area-inset-top)",
        }}
      >
        <Toolbar>
          <Hidden smDown>
            <Box
              display="flex"
              alignItems="center"
              sx={{
                img: {
                  width: 100,
                  height: "auto",
                  mr: 3,
                },
              }}
            >
              <img
                alt="Lagardere Travel Retail"
                src="/lagardere-travel-retail-logo.svg"
              />
            </Box>
          </Hidden>
          <Typography
            variant="h6"
            component="div"
            color="#013063"
            sx={{ mr: { xs: 2, md: 4 } }}
          >
            Fixnote
          </Typography>

          <LayoutAppBarMenu />

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Hidden smDown>
              <Box sx={{ mx: 3, mt: -1 }}>
                <HeadwayWidget
                  account="765vbx"
                  translations={{
                    title: "Přehled změn",
                    readMore: "Číst více",
                    footer: "Číst více",
                  }}
                />
              </Box>
            </Hidden>

            <Box
              sx={{
                color: (theme) => theme.palette.grey[600],
                textAlign: "right",
              }}
            >
              <LayoutAppBarProfile profile={profile} />
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

type LayoutProps = {
  children: React.ReactNode;
};

const Layout = (props: LayoutProps) => {
  return (
    <>
      <LayoutAppBar />

      <Toolbar />

      <Box sx={{ mt: 7 }}>{props.children}</Box>

      <Footer />
    </>
  );
};

export default Layout;
