import { RevisionType } from "../../../types";
import { AppDispatch } from "../../../app/store";

export const handleDownloadRevision =
  (revision: RevisionType) => (dispatch: AppDispatch) => {
    const link = document.createElement("a");
    link.href =
      process.env.REACT_APP_ENDPOINT_SERVER +
      "/files/originals/" +
      revision.filePath;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
