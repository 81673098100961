import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import revisionSlice from "../features/revision/slices/revisionSlice";
import authSlice from "../features/auth/slices/authSlice";
import enumSlice from "../features/enum/slices/enumSlice";
import toastMiddleware from "../features/toast/middlewares/toast.middleware";
import issueSlice from "../features/issue/slices/issueSlice";
import issueMiddleware from "../features/issue/middlewares/issue.middleware";
import enumMiddleware from "../features/enum/middlewares/enum.middleware";
import revisionMiddleware from "../features/revision/middlewares/revision.middleware";
import documentSlice from "../features/document/slices/document.slice";
import documentMiddleware from "../features/document/middlewares/document.middleware";
import unitSlice from "../features/unit/slices/unit.slice";
import assetSlice from "../features/asset/slices/asset.slice";
import assetMiddleware from "../features/asset/middlewares/asset.middleware";
import shareEntitySlice from "../features/common/slices/shareEntity.slice";
import shareEntityMiddleware from "../features/common/middlewares/shareEntity.middleware";

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    issue: issueSlice.reducer,
    revision: revisionSlice.reducer,
    document: documentSlice.reducer,
    enum: enumSlice.reducer,
    unit: unitSlice.reducer,
    asset: assetSlice.reducer,
    shareEntity: shareEntitySlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(
      enumMiddleware.middleware,
      issueMiddleware.middleware,
      revisionMiddleware.middleware,
      documentMiddleware.middleware,
      toastMiddleware.middleware,
      assetMiddleware.middleware,
      toastMiddleware.middleware,
      shareEntityMiddleware.middleware
    ),
  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch; // Export a hook that can be reused to resolve types
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
