//@ts-nocheck

import { useAppSelector } from "../../../../../app/store";
import { selectAssetById } from "../../../selectors/asset.selector";
import { Box, Typography } from "@mui/material";
import React from "react";

const FieldUnitPlacement = ({ assetId }: { assetId: string }) => {
  const asset = useAppSelector((state) => selectAssetById(state, assetId));

  if (!asset) {
    return <></>;
  }

  return (
    <Box sx={{ p: 2, width: "100%", display: "flex" }}>
      <Typography
        variant={"body2"}
        sx={{ fontStyle: "italic", fontSize: "90%" }}
      >
        {asset.unitPlacement}
      </Typography>
    </Box>
  );
};

export const fieldUnitPlacement = {
  code: "unitPlacement",
  label: "Umístění",
  component: FieldUnitPlacement,
};
