import {
  AnyAction,
  createListenerMiddleware,
  ListenerMiddlewareInstance,
} from "@reduxjs/toolkit";
import { ListenerApiType } from "../../../types/api.types";
import assetSlice from "../slices/asset.slice";
import { effectAssetListFetch } from "./asset.effect";
import { selectAssetSearchProperty } from "../selectors/asset.selector";

const assetMiddleware: ListenerMiddlewareInstance = createListenerMiddleware();

//při požadavku na vyhledávání zařízení
assetMiddleware.startListening({
  type: assetSlice.actions.searchSubmit.type,
  effect: async (action: AnyAction, listenerApi: ListenerApiType) => {
    const queryParams = {
      fulltext: {
        operation: "=",
        value: selectAssetSearchProperty(listenerApi.getState(), {
          property: "fulltext",
        }),
      },
    };
    await effectAssetListFetch(action, listenerApi, {
      resetList: true,
      showFetchingIndicator: true,
      queryParams: queryParams,
    });
  },
});

//při zadání QR kódu
assetMiddleware.startListening({
  predicate: (action, currentState, previousState) => {
    return (
      [assetSlice.actions.searchPropertySet.type].includes(action.type) &&
      action.payload.hasOwnProperty("property") &&
      action.payload.property === "qrCode"
    );
  },
  effect: async (action: AnyAction, listenerApi: ListenerApiType) => {
    const queryParams = {
      qrCode: {
        operation: "=",
        value: selectAssetSearchProperty(listenerApi.getState(), {
          property: "qrCode",
        }),
      },
    };
    await effectAssetListFetch(action, listenerApi, {
      resetList: true,
      showFetchingIndicator: true,
      queryParams: queryParams,
    });
  },
});

//
// //při odeslání požadavku
// assetMiddleware.startListening({
//   predicate: (action, currentState, previousState) => {
//     return [issueSlice.actions.selectedIssueSubmit.type].includes(action.type);
//   },
//   effect: async (action, listenerApi) => {
//     await effectSelectedIssueSubmit(action, listenerApi);
//   },
// });
//
// //při aktualizaci požadavku v režimu čtení
// assetMiddleware.startListening({
//   predicate: (action, currentState, previousState) => {
//     const mode = selectSelectedIssueMode(currentState as unknown as RootState);
//     return (
//       !!mode &&
//       [
//         issueSlice.actions.selectedIssuePropertySet.type,
//         issueSlice.actions.selectedIssuePropertiesSet.type,
//       ].includes(action.type) &&
//       ["read"].includes(mode) &&
//       action.payload.property !== "attachmentInputs"
//     );
//   },
//   effect: async (action, listenerApi) => {
//     await effectSelectedIssueSubmit(action, listenerApi);
//   },
// });
//
// //při odeslání komentáře
// assetMiddleware.startListening({
//   type: issueSlice.actions.selectedIssueCommentSubmit.type,
//   effect: effectSelectIssueCommentSubmit,
// });
//
// //při přepnutí otevřených / uzavřených požadavků
// assetMiddleware.startListening({
//   predicate: (action, currentState, previousState) => {
//     const showClosedCurrent = selectIssueFilterProperty(currentState, {
//       property: "showClosed",
//     });
//     const showClosedPrev = selectIssueFilterProperty(previousState, {
//       property: "showClosed",
//     });
//     return (
//       [
//         issueSlice.actions.issueFilterSet.type,
//         issueSlice.actions.issueFilterPropertySet.type,
//       ].includes(action.type) && showClosedPrev !== showClosedCurrent
//     );
//   },
//   effect: async (action: AnyAction, listenerApi: ListenerApiType) => {
//     const queryParams = {
//       showClosed: {
//         operation: "=",
//         value: selectIssueFilterProperty(listenerApi.getState(), {
//           property: "showClosed",
//         }),
//       },
//     };
//     await effectIssueListFetch(action, listenerApi, {
//       queryParams,
//       showFetchingIndicator: true,
//       resetList: true,
//     });
//   },
// });
//
// //při autorefetch
// let timeoutObj: any = null;
// assetMiddleware.startListening({
//   predicate: (action, currentState, previousState) => {
//     return action.type === issueSlice.actions.listFetchingPolling.type;
//   },
//   effect: async (action, listenerApi) => {
//     clearTimeout(timeoutObj);
//     if (action.payload.enabled) {
//       const state = listenerApi.getState() as RootState;
//       const listFetchingPolling = state.issue.listFetchingPolling;
//       if (
//         listFetchingPolling.nextFetchIn === 0 ||
//         action.payload.nextFetchIn === 0
//       ) {
//         const lastChangeAt = selectIssuesAll(state).reduce(
//           (prev: string, current: IssueType) => {
//             const changeAt = !!current.updatedAt
//               ? current.updatedAt
//               : current.createdAt;
//             return prev > changeAt ? prev : changeAt;
//           },
//           ""
//         );
//
//         const queryParams = {
//           lastChangeAt: {
//             operation: "=",
//             value: lastChangeAt,
//           },
//         };
//
//         await effectEnumsFetch_serviceTechnician(action, listenerApi);
//         await effectIssueListFetch(action, listenerApi, {
//           showFetchingIndicator: false,
//           resetList: false,
//           queryParams: queryParams,
//         });
//
//         //zahájení nového odečtu
//         listenerApi.dispatch(
//           issueSlice.actions.listFetchingPolling({
//             ...listFetchingPolling,
//             nextFetchIn: action.payload.interval,
//           })
//         );
//       } else {
//         //pokračování v odečtu
//         timeoutObj = setTimeout(() => {
//           listenerApi.dispatch(
//             issueSlice.actions.listFetchingPolling({
//               ...listFetchingPolling,
//               nextFetchIn: listFetchingPolling.nextFetchIn - 1,
//             })
//           );
//         }, 1000);
//       }
//     }
//   },
// });

export default assetMiddleware;
