import {
  createListenerMiddleware,
  Dictionary,
  isAnyOf,
} from "@reduxjs/toolkit";
import issueSlice from "../../issue/slices/issueSlice";
import {
  selectSelectedIssue,
  selectSelectedIssueMode,
} from "../../issue/selectors/issueSelectors";
import { RootState } from "../../../app/store";
import { toast, Bounce } from "react-toastify";
import {
  DocumentType,
  IssueType,
  Nullable,
  RevisionType,
} from "../../../types";
import { ToastTemplateType, ToastType } from "../types/toast.types";
import { toastTemplates } from "../templates/toast.templates";
import revisionSlice from "../../revision/slices/revisionSlice";
import {
  selectSelectedRevision,
  selectSelectedRevisionMode,
} from "../../revision/selectors/revisionSelectors";
import documentSlice from "../../document/slices/document.slice";
import {
  selectSelectedDocument,
  selectSelectedDocumentMode,
} from "../../document/selectors/document.selectors";

const toastStack: Dictionary<ToastType> = {};

const upsertToast = (
  template: ToastTemplateType,
  statusCode: "pending" | "fulfilled",
  id: string,
  args: {
    issue?: Nullable<IssueType>;
    revision?: Nullable<RevisionType>;
    document?: Nullable<DocumentType>;
    count?: Nullable<number>;
  }
) => {
  if (statusCode === "pending") {
    const toastId = toast.loading(
      template.pending.render(args) as JSX.Element,
      {
        type: toast.TYPE.INFO,
      }
    );
    toastStack[id] = {
      toastId,
      toastTypeCode: template.code,
    };
  }

  if (statusCode === "fulfilled") {
    // @ts-ignore
    toast.update(toastStack[id].toastId, {
      render: template.fulfilled.render(args) as JSX.Element,
      type: toast.TYPE.SUCCESS,
      autoClose: 2000,
      transition: Bounce,
      isLoading: false,
      onClose: () => {
        delete toastStack[id];
      },
    });
  }
};

const toastMiddleware = createListenerMiddleware();

toastMiddleware.startListening({
  matcher: isAnyOf(
    issueSlice.actions.selectedIssueSubmit,
    issueSlice.actions.selectedIssueCommentSubmit,
    issueSlice.actions.selectedIssuePropertySet,
    issueSlice.actions.selectedIssuePropertiesSet
  ),
  effect: (action, listenerApi) => {
    const originalState: RootState = listenerApi.getState() as RootState;
    const issue = selectSelectedIssue(originalState);
    const mode = selectSelectedIssueMode(originalState);

    if (!!issue) {
      toastTemplates.forEach((template) => {
        if (template.pending.when(action, listenerApi, { issue })) {
          upsertToast(template, "pending", mode === "create" ? "0" : issue.id, {
            issue,
            count: issue.unitIds?.length || 1,
          });
        }
      });
    }
  },
});

toastMiddleware.startListening({
  type: issueSlice.actions.issueListUpsertOne.type,
  effect: (action: any, listenerApi) => {
    let id;
    if (toastStack.hasOwnProperty("0")) {
      id = "0";
    } else {
      id = action.payload.id;
    }
    if (toastStack.hasOwnProperty(id)) {
      const toast = toastStack[id];
      if (!!toast) {
        const templateIndex = toastTemplates.findIndex(
          (tt) => tt.code === toast.toastTypeCode
        );
        if (templateIndex > -1) {
          upsertToast(toastTemplates[templateIndex], "fulfilled", id, {
            issue: action.payload,
          });
        }
      }
    }
  },
});

toastMiddleware.startListening({
  type: issueSlice.actions.issueListUpsertMany.type,
  effect: (action: any, listenerApi) => {
    let id;
    if (toastStack.hasOwnProperty("0")) {
      id = "0";
    } else {
      id = action.payload.id;
    }
    if (toastStack.hasOwnProperty(id)) {
      const toast = toastStack[id];
      if (!!toast) {
        const templateIndex = toastTemplates.findIndex(
          (tt) => tt.code === toast.toastTypeCode
        );
        if (templateIndex > -1) {
          upsertToast(toastTemplates[templateIndex], "fulfilled", id, {
            count: action.payload.length,
          });
        }
      }
    }
  },
});

toastMiddleware.startListening({
  matcher: isAnyOf(revisionSlice.actions.selectedRevisionSubmit),
  effect: (action, listenerApi) => {
    const originalState: RootState = listenerApi.getState() as RootState;
    const revision = selectSelectedRevision(originalState);
    const mode = selectSelectedRevisionMode(originalState);

    if (!!revision) {
      toastTemplates.forEach((template) => {
        if (template.pending.when(action, listenerApi, { revision })) {
          upsertToast(
            template,
            "pending",
            mode === "create" ? "0" : revision.id,
            { revision }
          );
        }
      });
    }
  },
});

toastMiddleware.startListening({
  type: revisionSlice.actions.revisionListUpsertOne.type,
  effect: (action: any, listenerApi) => {
    let id;
    if (toastStack.hasOwnProperty("0")) {
      id = "0";
    } else {
      id = action.payload.id;
    }
    if (toastStack.hasOwnProperty(id)) {
      const toast = toastStack[id];
      if (!!toast) {
        const templateIndex = toastTemplates.findIndex(
          (tt) => tt.code === toast.toastTypeCode
        );
        if (templateIndex > -1) {
          upsertToast(toastTemplates[templateIndex], "fulfilled", id, {
            revision: action.payload,
          });
        }
      }
    }
  },
});

toastMiddleware.startListening({
  matcher: isAnyOf(documentSlice.actions.selectedDocumentSubmit),
  effect: (action, listenerApi) => {
    const originalState: RootState = listenerApi.getState() as RootState;
    const document = selectSelectedDocument(originalState);
    const mode = selectSelectedDocumentMode(originalState);

    if (!!document) {
      toastTemplates.forEach((template) => {
        if (
          template.pending.when(action, listenerApi, {
            document,
          })
        ) {
          upsertToast(
            template,
            "pending",
            mode === "create" ? "0" : document.id,
            { document }
          );
        }
      });
    }
  },
});

toastMiddleware.startListening({
  type: documentSlice.actions.documentListUpsertOne.type,
  effect: (action: any, listenerApi) => {
    let id;
    if (toastStack.hasOwnProperty("0")) {
      id = "0";
    } else {
      id = action.payload.id;
    }
    if (toastStack.hasOwnProperty(id)) {
      const toast = toastStack[id];
      if (!!toast) {
        const templateIndex = toastTemplates.findIndex(
          (tt) => tt.code === toast.toastTypeCode
        );
        if (templateIndex > -1) {
          upsertToast(toastTemplates[templateIndex], "fulfilled", id, {
            document: action.payload,
          });
        }
      }
    }
  },
});

toastMiddleware.startListening({
  matcher: isAnyOf(documentSlice.actions.documentDelete),
  effect: (action, listenerApi) => {
    const document = action.payload;

    if (!!document) {
      toastTemplates.forEach((template) => {
        if (
          template.pending.when(action, listenerApi, {
            document,
          })
        ) {
          upsertToast(template, "pending", "document_0", { document });
        }
      });
    }
  },
});

toastMiddleware.startListening({
  type: documentSlice.actions.documentListRemoveOne.type,
  effect: (action: any, listenerApi) => {
    const id = "document_0";
    if (toastStack.hasOwnProperty(id)) {
      const toast = toastStack[id];
      if (!!toast) {
        const templateIndex = toastTemplates.findIndex(
          (tt) => tt.code === toast.toastTypeCode
        );
        if (templateIndex > -1) {
          upsertToast(toastTemplates[templateIndex], "fulfilled", id, {
            document: action.payload,
          });
        }
      }
    }
  },
});

toastMiddleware.startListening({
  matcher: isAnyOf(revisionSlice.actions.revisionDelete),
  effect: (action, listenerApi) => {
    const revision = action.payload;

    if (!!revision) {
      toastTemplates.forEach((template) => {
        if (
          template.pending.when(action, listenerApi, {
            revision,
          })
        ) {
          upsertToast(template, "pending", revision.id, { revision });
        }
      });
    }
  },
});

toastMiddleware.startListening({
  type: revisionSlice.actions.revisionListRemoveOne.type,
  effect: (action: any, listenerApi) => {
    const id = action.payload;
    if (toastStack.hasOwnProperty(id)) {
      const toast = toastStack[id];
      if (!!toast) {
        const templateIndex = toastTemplates.findIndex(
          (tt) => tt.code === toast.toastTypeCode
        );
        if (templateIndex > -1) {
          upsertToast(toastTemplates[templateIndex], "fulfilled", id, {
            document: action.payload,
          });
        }
      }
    }
  },
});

export default toastMiddleware;
