import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import router from "./app/router";
import { RouterProvider, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./app/store";
import { theme } from "./themes/theme";
import { ThemeProvider } from "@mui/material";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { cs } from "date-fns/locale";
import { LocalizationProvider } from "@mui/x-date-pickers";
import ReactGA from "react-ga";
import { SnackbarProvider } from "notistack";
import ShareEntityProvider from "./features/common/components/ShareEntity/ShareEntityProvider";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://7e317e533fd54d5f90dc95071b2bf566@o373870.ingest.sentry.io/5190992",
  integrations: [
    new Sentry.BrowserTracing({
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay()
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost:3000", /^https:\/\/app\.fixnote\.cz/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

if (process.env.REACT_APP_ENV !== "development") {
  ReactGA.initialize("UA-153862790-1");
  ReactGA.pageview(window.location.pathname + window.location.search);
}

root.render(
  // <React.StrictMode>
  <Sentry.ErrorBoundary>
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={cs}>
        <ThemeProvider theme={theme}>
          <ShareEntityProvider>
            <SnackbarProvider>
              <RouterProvider router={router} />
            </SnackbarProvider>
          </ShareEntityProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </Provider>
  </Sentry.ErrorBoundary>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
