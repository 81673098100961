import {
  ArrowForward,
  CheckCircle,
  CheckCircleOutline,
  RotateLeft,
} from "@mui/icons-material";
import issueSlice from "../../features/issue/slices/issueSlice";
import store, { AppDispatch } from "../../app/store";
import { ISSUE_STATUS_CODE__new } from "./issueStatuses";
import { selectSelectedIssue } from "../../features/issue/selectors/issueSelectors";
import { ISSUE_ATTACHMENT_TYPE_CODE__serviceList } from "./issueAttachmentTypes";

export const ISSUE_ACTION_CODE__forwardIssueToHeadquarters =
  "forwardIssueToHeadquarters";
export const ISSUE_ACTION_CODE__markIssueAsDoneByUnit = "markIssueAsDoneByUnit";
export const ISSUE_ACTION_CODE__markIssueAsDoneByServiceTechnician =
  "markIssueAsDoneByServiceTechnician";
export const ISSUE_ACTION_CODE__forwardToServiceCompany =
  "forwardToServiceCompany";
export const ISSUE_ACTION_CODE__markIssueAsDoneByServiceCompany =
  "markIssueAsDoneByServiceCompany";
export const ISSUE_ACTION_CODE__markIssueAsDoneByHeadquarters =
  "markIssueAsDoneByHeadquarters";
export const ISSUE_ACTION_CODE__considerIssueAsDoneByHeadquarters =
  "considerIssueAsDoneByHeadquarters";
export const ISSUE_ACTION_CODE__resetIssue = "resetIssue";

const handleMarkIssueAsDone = ({
  dispatch,
  property,
}: {
  dispatch: AppDispatch;
  property: string;
}) => {
  const issue = selectSelectedIssue(store.getState());
  if (
    issue?.attachments.filter(
      (a) => a.type === ISSUE_ATTACHMENT_TYPE_CODE__serviceList
    ).length === 0
  ) {
    dispatch(
      issueSlice.actions.markingAsDoneConfirmDialogSet({
        open: true,
        actionType: property,
      })
    );
  } else {
    dispatch(
      issueSlice.actions.selectedIssuePropertiesSet([
        {
          property: property,
          value: new Date().toISOString(),
        },
      ])
    );
  }
};

const ISSUE_ACTIONS = {
  [ISSUE_ACTION_CODE__forwardIssueToHeadquarters]: {
    code: ISSUE_ACTION_CODE__forwardIssueToHeadquarters,
    label: "Přesměrovat na centrálu",
    icon: ArrowForward,
    onClickFn: ({ dispatch }: { dispatch: AppDispatch }) => {
      dispatch(
        issueSlice.actions.selectedIssuePropertiesSet([
          {
            property: "forwardedToHeadquartersAt",
            value: new Date().toISOString(),
          },
        ])
      );
    },
  },
  [ISSUE_ACTION_CODE__markIssueAsDoneByUnit]: {
    code: ISSUE_ACTION_CODE__markIssueAsDoneByUnit,
    label: "Označit jako vyřízený",
    icon: CheckCircle,
    onClickFn: ({ dispatch }: { dispatch: AppDispatch }) => {
      dispatch(
        issueSlice.actions.selectedIssuePropertiesSet([
          { property: "markedAsDoneByUnitAt", value: new Date().toISOString() },
        ])
      );
    },
  },
  [ISSUE_ACTION_CODE__markIssueAsDoneByServiceTechnician]: {
    code: ISSUE_ACTION_CODE__markIssueAsDoneByServiceTechnician,
    label: "Označit jako vyřízený",
    icon: CheckCircle,
    onClickFn: ({ dispatch }: { dispatch: AppDispatch }) => {
      handleMarkIssueAsDone({
        dispatch,
        property: "markedAsDoneByServiceTechnicianAt",
      });
    },
  },
  [ISSUE_ACTION_CODE__forwardToServiceCompany]: {
    code: ISSUE_ACTION_CODE__forwardToServiceCompany,
    label: "Označit jako vyřízený", //TODO - Servisní technik předává zpět na Servisní společnost
    icon: CheckCircle,
    onClickFn: ({ dispatch }: { dispatch: AppDispatch }) => {
      dispatch(
        issueSlice.actions.selectedIssuePropertiesSet([
          {
            property: "forwardedToServiceCompanyAt",
            value: new Date().toISOString(),
          },
        ])
      );
    },
  },
  [ISSUE_ACTION_CODE__markIssueAsDoneByServiceCompany]: {
    code: ISSUE_ACTION_CODE__markIssueAsDoneByServiceCompany,
    label: "Označit jako vyřízený",
    icon: CheckCircle,
    onClickFn: ({ dispatch }: { dispatch: AppDispatch }) => {
      handleMarkIssueAsDone({
        dispatch,
        property: "markedAsDoneByServiceCompanyAt",
      });
    },
  },
  [ISSUE_ACTION_CODE__markIssueAsDoneByHeadquarters]: {
    code: ISSUE_ACTION_CODE__markIssueAsDoneByHeadquarters,
    label: "Označit jako vyřízený",
    icon: CheckCircle,
    onClickFn: ({ dispatch }: { dispatch: AppDispatch }) => {
      handleMarkIssueAsDone({
        dispatch,
        property: "markedAsDoneByHeadquartersAt",
      });
    },
  },
  [ISSUE_ACTION_CODE__considerIssueAsDoneByHeadquarters]: {
    code: ISSUE_ACTION_CODE__considerIssueAsDoneByHeadquarters,
    label: "Považovat za vyřízený",
    icon: CheckCircleOutline,
    onClickFn: ({ dispatch }: { dispatch: AppDispatch }) => {
      dispatch(
        issueSlice.actions.selectedIssuePropertiesSet([
          {
            property: "consideredAsDoneByHeadquartersAt",
            value: new Date().toISOString(),
          },
        ])
      );
    },
  },
  [ISSUE_ACTION_CODE__resetIssue]: {
    code: ISSUE_ACTION_CODE__resetIssue,
    label: "Resetovat požadavek",
    icon: RotateLeft,
    onClickFn: ({ dispatch }: { dispatch: AppDispatch }) => {
      dispatch(
        issueSlice.actions.selectedIssuePropertiesSet([
          // { property: "forwardedToHeadquartersAt", value: null },
          { property: "sentToServiceCompanyAt", value: null },
          { property: "sentToServiceTechnicianAt", value: null },
          { property: "markedAsDoneByUnitAt", value: null },
          { property: "markedAsDoneByServiceTechnicianAt", value: null },
          { property: "forwardedToServiceCompanyAt", value: null },
          { property: "markedAsDoneByServiceCompanyAt", value: null },
          { property: "markedAsDoneByHeadquartersAt", value: null },
          { property: "consideredAsDoneByHeadquartersAt", value: null },
          { property: "serviceCompanyId", value: null },
          { property: "serviceTechnicianId", value: null },
          { property: "statusId", value: ISSUE_STATUS_CODE__new },
          { property: "open", value: true },
        ])
      );
    },
  },
};

export type userTypeCodeType = keyof typeof ISSUE_ACTIONS;

export const getIssueActionByIssueActionCode = (code: userTypeCodeType) => {
  return ISSUE_ACTIONS[code];
};
