import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import {
  selectSelectedIssueMode,
  selectSelectedIssueProperty,
} from "../../../selectors/issueSelectors";
import issueSlice from "../../../slices/issueSlice";
import { TextField } from "@mui/material";

const IssueDetailFieldDescriptionEditable = (props: any) => {
  const { handleSubmit } = props;

  const dispatch = useAppDispatch();

  const changeValue = (value: any) => {
    dispatch(
      issueSlice.actions.selectedIssuePropertySet({
        property: "description",
        value: value,
      })
    );
  };

  const value = useAppSelector((state) =>
    selectSelectedIssueProperty(state, { property: "description" })
  );

  return (
    <TextField
      label={"Požadavek"}
      fullWidth={true}
      multiline={true}
      rows={6}
      value={value}
      onChange={(event) => {
        changeValue(event.target.value);
      }}
      onKeyDown={(ev) => {
        if (ev.key === "Enter" && ev.metaKey || ev.key === "Enter" && ev.ctrlKey) {
          ev.preventDefault();
          handleSubmit();
        }
      }}
    />
  );
};

const IssueDetailFieldDescription = (props: any) => {
  const { handleSubmit } = props;

  const mode = useAppSelector(selectSelectedIssueMode);

  switch (mode) {
    case "create": {
      return <IssueDetailFieldDescriptionEditable handleSubmit={handleSubmit} />;
    }
    case "update": {
      return <IssueDetailFieldDescriptionEditable handleSubmit={handleSubmit} />;
    }
    default: {
      return <></>;
    }
  }
};

export default IssueDetailFieldDescription;
