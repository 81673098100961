import { AppDispatch } from "../../../app/store";
import authSlice from "../slices/authSlice";
import { NavigateFunction } from "react-router-dom";
import { ROUTER_PATH_LOGIN } from "../../../app/router";
import axios from "axios";
import issueSlice from "../../issue/slices/issueSlice";

export const loadAuthToken = (): string | null => {
  return localStorage.getItem("authToken");
};

export const saveAuthToken = (value: string) => {
  localStorage.setItem("authToken", value);
};

export const logOut = (dispatch: AppDispatch, navigate: NavigateFunction) => {
  localStorage.removeItem("authToken");
  dispatch(issueSlice.actions.issueListRemoveAll());
  dispatch(authSlice.actions.profileSet(null));
  navigate(ROUTER_PATH_LOGIN);
};

export const getURLAccessToken = (): Promise<string | null> => {
  return new Promise((resolve, reject) => {
    const params: URLSearchParams = new Proxy(
      new URLSearchParams(window.location.search),
      {
        get: (searchParams: URLSearchParams, prop: string) =>
          searchParams.get(prop),
      }
    );

    // @ts-ignore
    if (!!params && !!params["a"]) {
      // @ts-ignore
      resolve(params["a"]);
    }

    resolve(null);
  });
};

export const getAuthTokenViaURLAccessToken = (
  urlAccessToken: string
): Promise<Response> => {
  return axios.post(process.env.REACT_APP_ENDPOINT_SERVER + "/auth", {
    urlToken: urlAccessToken,
  });
};

export const getAuthTokenViaCredentials = (
  loginName: string,
  password: string
): Promise<Response> => {
  return axios.post(process.env.REACT_APP_ENDPOINT_SERVER + "/auth", {
    loginName: loginName,
    plainPassword: password,
  });
};

export const fetchProfile = (authToken: string): Promise<Response> => {
  return axios.get(process.env.REACT_APP_ENDPOINT_SERVER + "/profile", {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};
