import { useState } from "react";
import { Alert, Box, Paper, TextField, Typography } from "@mui/material";
import { getAuthTokenViaCredentials, saveAuthToken } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import { LoadingButton } from "@mui/lab";
import { loadProfile } from "../../../../app/profile.utils";
import { useAppDispatch } from "../../../../app/store";

const LogIn = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [loginName, setLoginName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [fetching, setFetching] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleSubmit = async () => {
    setErrorMessage("");
    setFetching(true);
    getAuthTokenViaCredentials(loginName, password)
      .then(async (response: any) => {
        //console.log(response);
        if (!!response && response.status === 200 && !!response.data) {
          await saveAuthToken(response.data.token);
          await loadProfile(response.data.token, { dispatch, navigate });
        }
      })
      .catch((e: any) => {
        setErrorMessage("Přihlášení se nepodařilo.");
        setFetching(false);
      });
  };

  return (
    <>
      <Container component="main" maxWidth="xs" sx={{ mt: 8 }}>
        <CssBaseline />
        <Paper
          sx={{
            border: (theme) => `1px solid ${theme.palette.divider}`,
            mb: 4,
          }}
          elevation={0}
        >
          <Box p={3}>
            <Typography
              color={"primary"}
              component="h1"
              variant="h3"
              fontWeight={"500"}
              mb={2}
              textAlign="center"
            >
              Fixnote
            </Typography>

            <TextField
              label="Přihlašovací jméno"
              required
              name={"loginName"}
              variant="outlined"
              margin="normal"
              fullWidth
              value={loginName}
              autoFocus
              onChange={(event) => {
                setLoginName(event.target.value);
              }}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  handleSubmit();
                  ev.preventDefault();
                }
              }}
            />

            <TextField
              type={"password"}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Heslo"
              name={"password"}
              value={password}
              onChange={(event) => {
                setPassword(event.target.value);
              }}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  handleSubmit();
                  ev.preventDefault();
                }
              }}
            />

            {errorMessage !== "" && (
              <Alert sx={{ mt: 1 }} severity={"error"}>
                {errorMessage}
              </Alert>
            )}

            <LoadingButton
              disabled={fetching}
              loading={fetching}
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
              onClick={() => {
                handleSubmit();
              }}
            >
              Přihlásit
            </LoadingButton>
          </Box>
        </Paper>

        <Link
          href="https://www.query.cz"
          sx={{ opacity: ".3", display: "block", textAlign: "center" }}
          target="_blank"
        >
          <img alt="Query" width="24" height="29" src="/images/query.svg" />
        </Link>

        <Typography
          sx={{
            color: (theme) => theme.palette.grey[600],
            textAlign: "center",
            marginTop: 1,
            opacity: ".3",
            fontSize: 12,
          }}
          variant="body1"
        >
          <code>{process.env.REACT_APP_VERSION}</code>
        </Typography>
      </Container>
    </>
  );
};

export default LogIn;
