import { AnyAction } from "@reduxjs/toolkit";
import {
  effectIssueListFetchArgsType,
  ListenerApiType,
} from "../../../types/api.types";
import { assetGetAll } from "../../../api/api";
import assetSlice from "../slices/asset.slice";
import { AssetType } from "../../../types/asset.types";

export const effectAssetListFetch = async (
  action: AnyAction,
  listenerApi: ListenerApiType,
  args: effectIssueListFetchArgsType = {
    showFetchingIndicator: true,
    resetList: false,
    queryParams: {},
  }
) => {
  if (args.showFetchingIndicator) {
    listenerApi.dispatch(assetSlice.actions.listFetching(true));
    listenerApi.dispatch(assetSlice.actions.listFetchingStatus("fetching"));
  }

  const queryParams = args.queryParams;
  assetGetAll({ queryParams: queryParams })
    .then((response: any) => {
      if (response.status === 200) {
        const assets: AssetType[] = response.data.assets;
        listenerApi.dispatch(assetSlice.actions.listFetchingStatus("success"));
        if (args.resetList) {
          listenerApi.dispatch(assetSlice.actions.listSet(assets));
        } else {
          listenerApi.dispatch(assetSlice.actions.listUpsertMany(assets));
        }
      }
    })
    .catch((error) => {
      listenerApi.dispatch(assetSlice.actions.listFetchingStatus("failure"));
    })
    .finally(() => {
      listenerApi.dispatch(assetSlice.actions.listFetching(false));
    });
};
