import { Alert, Link } from "@mui/material";
import { Container } from "@mui/system";
import { Helmet } from "react-helmet";
import LogIn from "../features/auth/components/LogIn/LogIn";

const LogInView = () => {
  return (
    <>
      <Helmet>
        <title>Přihlášení — Fixnote</title>
        <meta name="robots" content="noindex,nofollow"></meta>
      </Helmet>

      <LogIn />

      <Container component="main" maxWidth="xs" sx={{ mt: 4 }}>
        <Alert severity="info">
          Nedaří se vám přihlášení do nové verze aplikace nebo jste zapomněl/a
          heslo?
          <p style={{ marginBottom: 0 }}>
            Napište nám na{" "}
            <Link href="mailto:hello@query.cz">hello@query.cz</Link> a pomůžeme vám.
          </p>
        </Alert>
      </Container>
    </>
  );
};

export default LogInView;
