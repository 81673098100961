//@ts-nocheck

import { useAppSelector } from "../../../../../app/store";
import { selectAssetById } from "../../../selectors/asset.selector";
import { Box } from "@mui/system";
import React from "react";
import { AssetType } from "../../../../../types/asset.types";
import { Avatar, AvatarGroup, Chip, Typography } from "@mui/material";
import { Fancybox } from "@fancyapps/ui/dist/fancybox.esm";
import { FILE_TYPE_CODE__asset } from "../../../../../libraries/enums/fileTypes";
import Identifier from "../../Identifier/Identifier";
import ChipAssetStatus from "../../Chip/ChipAssetStatus";
import { getAssetStatusByCode } from "../../../../../libraries/enums/assetStatuses";

export const FieldAssetContent = ({
  asset,
  isDetail = false,
}: {
  asset: AssetType;
  isDetail?: boolean | undefined;
}) => {
  const files = !!asset
    ? [...asset.files]
        .filter((a) => a.type === FILE_TYPE_CODE__asset)
        .sort((a, b) => a.position - b.position)
    : [];

  const toggleFancybox = (e: any) => {
    e.stopPropagation();
    const fancybox = Fancybox.show(
      files.map((file) => {
        return {
          type: "image",
          src:
            process.env.REACT_APP_ENDPOINT_SERVER +
            "/files/originals" +
            file.path,
        };
      }),
      {
        infinite: false,
      }
    );
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          mb: 1,
        }}
      >
        {!!asset.inventoryNumber && (
          // <Identifier typeId={'inventory'} value={asset.inventoryNumber} />
          <Chip
            label={asset.inventoryNumber}
            size="small"
            sx={{
              mr: 1,
            }}
          />
        )}
        <Box sx={{ mr: 1, my: 0.5 }}>
          <ChipAssetStatus
            assetStatusCode={asset.statusId}
            label={getAssetStatusByCode(asset.statusId).label}
            active={true}
            size={"small"}
          />
        </Box>
        <Box sx={{ my: 0.5 }}>
          {/*<ChipIssueStatus*/}
          {/*  issueStatusCode={issue.statusId}*/}
          {/*  label={getIssueStatusByCode(issue.statusId).label}*/}
          {/*  active={true}*/}
          {/*  size={"small"}*/}
          {/*/>*/}
        </Box>
      </Box>
      <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
        <AvatarGroup
          spacing="small"
          max={2}
          variant="circular"
          total={files.length}
          sx={{ mr: files.length === 0 ? 0 : 1, cursor: "pointer" }}
          onClick={toggleFancybox}
        >
          {files.map((file) => {
            return (
              <Avatar
                key={file.id}
                src={
                  process.env.REACT_APP_ENDPOINT_SERVER +
                  "/files/thumbnails" +
                  file.path
                }
              />
            );
          })}
        </AvatarGroup>

        <Typography
          sx={{
            overflow: "hidden",
          }}
          component="div"
          variant="body2"
        >
          {isDetail ? (
            <>{asset.title}</>
          ) : (
            <Box
              sx={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {asset.title}
            </Box>
          )}
        </Typography>
      </Box>
    </>

    // <Box sx={{ p: 2, width: "100%" }}>
    //   <Box
    //     sx={{
    //       display: "flex",
    //       flexWrap: "wrap",
    //       alignItems: "center",
    //       mb: 1,
    //     }}
    //   >
    //     {/*<Box*/}
    //     {/*  sx={{*/}
    //     {/*    display: "flex",*/}
    //     {/*    "& > div": {*/}
    //     {/*      mr: 0.5,*/}
    //     {/*    },*/}
    //     {/*  }}*/}
    //     {/*>*/}
    //     {/*  <Identifier typeId={"inventory"} value={asset.inventoryNumber} />*/}
    //     {/*</Box>*/}
    //     <Chip
    //       label={asset.inventoryNumber}
    //       size="small"
    //       sx={{
    //         mr: 1,
    //         color: grey[600],
    //         bgcolor: "rgba(0, 0, 0, 0.04)",
    //       }}
    //     />
    //   </Box>
    //   <Box sx={{ fontWeight: 700 }}>{asset.title}</Box>
    // </Box>
  );
};

const FieldAsset = ({
  assetId,
  isDetail = false,
}: {
  asset: AssetType;
  isDetail?: boolean | undefined;
}) => {
  const asset = useAppSelector((state) => selectAssetById(state, assetId));

  if (!asset) {
    return <></>;
  }

  return (
    <Box sx={{ p: 2, width: "100%" }}>
      <FieldAssetContent asset={asset} isDetail={isDetail} />
    </Box>
  );
};

export const fieldAsset = {
  code: "title",
  label: "Zařízení",
  component: FieldAsset,
};
