export const ISSUE_ATTACHMENT_TYPE_CODE__attachment = "attachment";
export const ISSUE_ATTACHMENT_TYPE_CODE__serviceList = "serviceList";

const ISSUE_ATTACHMENT_TYPES = {
  [ISSUE_ATTACHMENT_TYPE_CODE__attachment]: {
    code: ISSUE_ATTACHMENT_TYPE_CODE__attachment,
  },
  [ISSUE_ATTACHMENT_TYPE_CODE__serviceList]: {
    code: ISSUE_ATTACHMENT_TYPE_CODE__serviceList,
  },
};

export type issueAttachmentTypeCodeType = keyof typeof ISSUE_ATTACHMENT_TYPES;

export const getIssueAttachmentTypeByCode = (
  code: issueAttachmentTypeCodeType
) => {
  return ISSUE_ATTACHMENT_TYPES[code];
};
