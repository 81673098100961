//@ts-nocheck

import React from "react";
import { Chip } from "@mui/material";
import { getAssetStatusByCode } from "../../../../libraries/enums/assetStatuses";

const ChipAssetStatus = (props) => {
  const { assetStatusCode, label, active = true, size = "medium" } = props;

  const assetStatus = getAssetStatusByCode(assetStatusCode);

  if (!assetStatus) {
    return <></>;
  }

  return (
    <Chip
      icon={React.createElement(assetStatus.icon)}
      label={label}
      size={size}
      sx={{
        bgcolor: assetStatus.bgcolor,
        opacity: active ? 1 : 0.2,
        transition: "all 250ms",
      }}
    />
  );
};

export default ChipAssetStatus;
