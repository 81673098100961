import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { ListConfigType, Nullable } from "../../../types";
import { buildInitialFilter } from "../builders/filter.builder";
import { AssetFilterType, AssetType } from "../../../types/asset.types";

type StateType = {
  search: AssetFilterType;
  filter: AssetFilterType;
  list: EntityState<AssetType>;
  listFetching: boolean;
  listFetchingStatus: "init" | "fetching" | "success" | "failure";
  listConfig: ListConfigType;
  selectedAsset: Nullable<AssetType>;
  selectedAssetMode: Nullable<"create" | "update" | "read">;
};

export const assetEntityAdapter = createEntityAdapter<AssetType>();

const initialState: StateType = {
  search: buildInitialFilter(),
  filter: buildInitialFilter(),
  list: assetEntityAdapter.getInitialState(),
  listFetching: false,
  listFetchingStatus: "init",
  listConfig: {
    page: 0,
    pageSize: 25,
    sortBy: null,
    sortByDirection: "ASC",
  },
  selectedAsset: null,
  selectedAssetMode: null,
};

const assetSlice = createSlice({
  name: "item",
  initialState: initialState,
  reducers: {
    listFetch: () => {},
    listFetching: (state: StateType, action: PayloadAction<boolean>) => {
      state.listFetching = action.payload;
    },
    listFetchingStatus: (
      state: StateType,
      action: PayloadAction<"init" | "fetching" | "success" | "failure">
    ) => {
      state.listFetchingStatus = action.payload;
    },
    listRemoveAll: (state: StateType) => {
      assetEntityAdapter.removeAll(state.list);
    },
    listSet: (state: StateType, action: PayloadAction<AssetType[]>) => {
      assetEntityAdapter.setAll(state.list, action.payload);
    },
    listUpsertMany: (state: StateType, action: PayloadAction<AssetType[]>) => {
      assetEntityAdapter.upsertMany(state.list, action.payload);
    },
    searchSet: (state: StateType, action: PayloadAction<AssetFilterType>) => {
      state.search = action.payload;
      state.listConfig = { ...state.listConfig, page: 0 };
    },
    searchPropertySet: (
      state: StateType,
      action: PayloadAction<{ property: string; value: any }>
    ) => {
      if (!state.search) {
        state.search = buildInitialFilter();
      }
      // @ts-ignore
      state.search[action.payload.property] = action.payload.value;
    },
    searchSubmit: (state: StateType) => {},
    filterSet: (state: StateType, action: PayloadAction<AssetFilterType>) => {
      state.filter = action.payload;
      state.listConfig = { ...state.listConfig, page: 0 };
    },
    filterPropertySet: (
      state: StateType,
      action: PayloadAction<{ property: string; value: any }>
    ) => {
      if (!state.filter) {
        state.filter = buildInitialFilter();
      }
      // @ts-ignore
      state.filter[action.payload.property] = action.payload.value;
      state.listConfig = { ...state.listConfig, page: 0 };
    },
    listUpsertOne: (state: StateType, action: PayloadAction<AssetType>) => {
      assetEntityAdapter.upsertOne(state.list, action.payload);
    },
    selectedAssetSet: (
      state: StateType,
      action: PayloadAction<Nullable<AssetType>>
    ) => {
      state.selectedAsset = action.payload;
    },
    selectedAssetModeSet: (
      state: StateType,
      action: PayloadAction<Nullable<"create" | "update" | "read">>
    ) => {
      state.selectedAssetMode = action.payload;
    },
    selectedAssetPropertySet: (
      state: StateType,
      action: PayloadAction<any>
    ) => {
      if (!!state.selectedAsset) {
        // @ts-ignore
        state.selectedAsset[action.payload.property] = action.payload.value;
      }
    },
    selectedAssetPropertiesSet: (
      state: StateType,
      action: PayloadAction<any>
    ) => {
      if (!!state.selectedAsset) {
        for (let i = 0; i < action.payload.length; i++) {
          // @ts-ignore
          state.selectedAsset[action.payload[i].property] =
            action.payload[i].value;
        }
      }
    },
    selectedAssetSetById: (state: StateType, action) => {
      const asset = state.list.entities[action.payload];
      if (!!asset) {
        state.selectedAsset = asset;
        state.selectedAssetMode = "read";
      }
    },
    selectedAssetSubmit: (state: StateType) => {},
    listConfigSet: (state: StateType, action: PayloadAction<any>) => {
      state.listConfig = { ...state.listConfig, ...action.payload };
    },
  },
});

export default assetSlice;
