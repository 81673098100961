import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { selectServiceCompanies } from "../../../../enum/selectors/enumSelectors";
import {
  selectSelectedIssueMode,
  selectSelectedIssueProperty,
} from "../../../selectors/issueSelectors";
import issueSlice from "../../../slices/issueSlice";

const IssueDetailFieldServiceCompanyEditable = () => {
  const dispatch = useAppDispatch();

  const changeValueId = (value: any) => {
    dispatch(
      issueSlice.actions.selectedIssuePropertiesSet([
        {
          property: "serviceCompanyId",
          value: value,
        },
        { property: "sentToServiceCompanyAt", value: null },
        { property: "attachmentInputs", value: [] },
      ])
    );
  };

  const valueId = useAppSelector((state) =>
    selectSelectedIssueProperty(state, { property: "serviceCompanyId" })
  );
  const options = useAppSelector((state) => selectServiceCompanies(state));

  return (
    <Autocomplete
      onChange={(event, newValue) => {
        if (!!newValue) {
          changeValueId(newValue.id);
        } else {
          changeValueId(null);
        }
      }}
      value={options.find((u) => u.id === valueId)}
      options={[...options].sort((a, b) => a.title.localeCompare(b.title))}
      renderOption={(props, option) => {
        return (
          <li key={option.id} {...props}>
            <strong>{option.title}</strong>
          </li>
        );
      }}
      getOptionLabel={(option) => option.title}
      renderInput={(params) => (
        <TextField {...params} label={"Servisní firma"} size="small" />
      )}
    />
  );
};

const IssueDetailFieldServiceCompany = () => {
  const mode = useAppSelector(selectSelectedIssueMode);

  switch (mode) {
    case "create": {
      return (
        <>
          <IssueDetailFieldServiceCompanyEditable />
        </>
      );
    }
    case "update": {
      return (
        <>
          <IssueDetailFieldServiceCompanyEditable />
        </>
      );
    }
    default: {
      //read
      return (
        <>
          <IssueDetailFieldServiceCompanyEditable />
        </>
      );
    }
  }
};

export default IssueDetailFieldServiceCompany;
