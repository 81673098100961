import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { Button, Dialog, DialogContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Html5QrcodeScanner } from "html5-qrcode";
import { useAppDispatch } from "../../../../app/store";
import assetSlice from "../../slices/asset.slice";

type ScannerProps = {
  onScan: (result: string) => void;
};

const qrcodeRegionId = "html5qr-code-full-region";

const Scanner = ({ onScan }: ScannerProps) => {
  const [r, setR] = useState<string | null>(null);

  const qrCodeSuccessCallback = (decodedText: any, decodedResult: any) => {
    if (!r && !!decodedText) {
      setR(decodedText);
      onScan(decodedText);
    }
  };

  const qrCodeErrorCallback = (error: any) => {};

  useEffect(() => {
    // when component mounts
    const config = {
      fps: 20,
      qrBox: 500,
      disableFlip: true,
    };
    const verbose = false;

    // Suceess callback is required.
    const html5QrcodeScanner = new Html5QrcodeScanner(
      qrcodeRegionId,
      config,
      verbose
    );
    html5QrcodeScanner.render(qrCodeSuccessCallback, qrCodeErrorCallback);

    // cleanup function when component will unmount
    return () => {
      html5QrcodeScanner.clear().catch((error: any) => {
        console.error("Failed to clear html5QrcodeScanner. ", error);
      });
    };
  }, []);

  return <div id={qrcodeRegionId} />;
};

const SearchViaQr = () => {
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleScan = (text: string) => {
    //dispatch(assetSlice.actions.searchPropertySet({ property: "qrCode", value: text }));
    dispatch(
      assetSlice.actions.filterPropertySet({ property: "qrCode", value: text })
    );
    handleClose();
  };

  return (
    <>
      <Button
        color={"secondary"}
        variant={"contained"}
        startIcon={<QrCodeScannerIcon />}
        onClick={handleOpen}
      >
        Načíst QR kód
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent sx={{ p: 0 }}>
          <Scanner onScan={handleScan} />
        </DialogContent>
      </Dialog>
      <Button
        variant={"text"}
        onClick={() => {
          handleScan("");
        }}
      >
        Reset
      </Button>
    </>
  );
};

export default SearchViaQr;
