//@ts-nocheck

import { selectProfile } from "../../auth/selectors/authSelectors";
import { createSelector } from "@reduxjs/toolkit";
import {
  PERMISSION_RIGHT_CODE__createAsset,
  permissionRightCodeType,
} from "../../../libraries/enums/permissionRights";

export const selectProfilePermission = createSelector(
  [selectProfile],
  (profile) => {
    return profile.permission;
  }
);

export const selectProfileHasPermissionRight = createSelector(
  [
    selectProfilePermission,
    (state, args: { permissionRight?: permissionRightCodeType }) =>
      args.permissionRight,
  ],
  (permission, permissionRight) => {
    if (!permission || !permissionRight) {
      return false;
    }

    //TODO - přetížení, aby uživatel vždy měl právo createItem
    if (permissionRight === PERMISSION_RIGHT_CODE__createAsset) {
      return true;
    }
    //TODO - end

    return permission.permissionRightIds.includes(permissionRight);
  }
);

export const selectProfileOnlyService = createSelector(
  [selectProfile],
  (profile) => {
    return profile?.onlyService;
  }
);
