import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { selectProfileHasPermissionRight } from "../../../common/selectors/commonSelectors";
import { PERMISSION_RIGHT_CODE__deleteRevision } from "../../../../libraries/enums/permissionRights";
import revisionSlice from "../../../revision/slices/revisionSlice";
import { RevisionType } from "../../../../types";
import { Button, Dialog, DialogActions, DialogContent, Divider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { selectRevisionSubjectById } from "../../../enum/selectors/enumSelectors";
import { formatDate } from "../../../common/utils/dateTime.utils";

type ActionProps = {
  revision: RevisionType;
  children?: any;
};

const RevisionActionDelete = (props: ActionProps) => {
  const { revision, children = null } = props;

  const dispatch = useAppDispatch();
  const revisionSubject = useAppSelector((state) =>
    selectRevisionSubjectById(state, revision.revisionSubjectId)
  );
  const hasPermissionRightDeleteRevision = useAppSelector((state) =>
    selectProfileHasPermissionRight(state, {
      permissionRight: PERMISSION_RIGHT_CODE__deleteRevision,
    })
  );

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    if (!!revision) {
      dispatch(revisionSlice.actions.revisionDelete(revision));
      handleClose();
    }
  };

  if (!revision || !hasPermissionRightDeleteRevision || !revisionSubject) {
    return <></>;
  }

  return (
    <>
      {!!children &&
        React.cloneElement(children, {
          onClick: () => {
            handleOpen();
          },
        })}
      {!children && (
        <Button
          onClick={() => {
            handleOpen();
          }}
          color={"error"}
          size="small"
        >
          <DeleteIcon fontSize={"small"} />
        </Button>
      )}
      <Dialog
        open={open}
        onClose={() => {
          handleClose();
        }}
      >
        <DialogContent>
          Opravdu chcete odstranit <strong>{revisionSubject.title}</strong> ze dne {formatDate(revision.revisionAt, "d. L. yyyy")}?
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            color={"inherit"}
            size={"small"}
            variant={"outlined"}
            startIcon={<CloseIcon />}
            onClick={() => {
              handleClose();
            }}
          >
            Neodstraňovat
          </Button>
          <Button
            color={"error"}
            size={"small"}
            variant={"contained"}
            startIcon={<DeleteIcon />}
            onClick={() => {
              handleDelete();
            }}
          >
            Ano, chci odstranit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RevisionActionDelete;
