export const FILE_TYPE_CODE__asset = "asset";
export const FILE_TYPE_CODE__asset_label = "asset_label";

const ISSUE_ATTACHMENT_TYPES = {
  [FILE_TYPE_CODE__asset]: {
    code: FILE_TYPE_CODE__asset,
  },
  [FILE_TYPE_CODE__asset_label]: {
    code: FILE_TYPE_CODE__asset_label,
  },
};

export type issueAttachmentTypeCodeType = keyof typeof ISSUE_ATTACHMENT_TYPES;

export const getIssueAttachmentTypeByCode = (
  code: issueAttachmentTypeCodeType
) => {
  return ISSUE_ATTACHMENT_TYPES[code];
};
