//@ts-nocheck

import { useAppSelector } from "../../../../../app/store";
import { selectAssetById } from "../../../selectors/asset.selector";
import { Box } from "@mui/system";
import React from "react";
import Identifier from "../../Identifier/Identifier";
import { FILE_TYPE_CODE__asset_label } from "../../../../../libraries/enums/fileTypes";
import { Fancybox } from "@fancyapps/ui/dist/fancybox.esm";
import { Avatar, AvatarGroup } from "@mui/material";

const FieldIdentifiers = ({ assetId }: { assetId: string }) => {
  const asset = useAppSelector((state) => selectAssetById(state, assetId));

  const qrCodes = !!asset
    ? [...asset.qrCodes].sort((a, b) => a.position - b.position)
    : [];

  const files = !!asset
    ? [...asset.files]
        .filter((a) => a.type === FILE_TYPE_CODE__asset_label)
        .sort((a, b) => a.position - b.position)
    : [];

  const toggleFancybox = (e: any) => {
    e.stopPropagation();
    const fancybox = Fancybox.show(
      files.map((file) => {
        return {
          type: "image",
          src:
            process.env.REACT_APP_ENDPOINT_SERVER +
            "/files/originals" +
            file.path,
        };
      }),
      {
        infinite: false,
      }
    );
  };

  if (!asset) {
    return <></>;
  }

  return (
    <Box sx={{ p: 2, width: "100%", display: "flex" }}>
      <AvatarGroup
        spacing="small"
        max={2}
        variant="circular"
        total={files.length}
        sx={{ mr: files.length === 0 ? 0 : 1, cursor: "pointer" }}
        onClick={toggleFancybox}
      >
        {files.map((file) => {
          return (
            <Avatar
              key={file.id}
              src={
                process.env.REACT_APP_ENDPOINT_SERVER +
                "/files/thumbnails" +
                file.path
              }
            />
          );
        })}
      </AvatarGroup>
      <Box sx={{ display: "flex", "& > div": { mr: 1 } }}>
        <Identifier typeId={"serial"} value={asset.serialNumber} />
        <Identifier typeId={"producer"} value={asset.producerNumber} />
        {qrCodes.map((qrCode) => {
          return (
            <Identifier
              key={qrCode.id}
              typeId={"qrCode"}
              value={qrCode.label}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export const fieldIdentifiers = {
  code: "identifiers",
  label: "Identifikace",
  component: FieldIdentifiers,
};
