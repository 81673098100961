import { issueEntityAdapter } from "../slices/issueSlice";
import { RootState } from "../../../app/store";
import { createSelector } from "@reduxjs/toolkit";
import { IssueFilterType, IssueType } from "../../../types";
import {
  selectBrands,
  selectUnitEntities,
  selectUnits,
} from "../../enum/selectors/enumSelectors";
import {
  ISSUE_STATUS_CODE__done,
  ISSUE_STATUS_CODE__inProgress,
  ISSUE_STATUS_CODE__new,
} from "../../../libraries/enums/issueStatuses";
import { ISSUE_TYPE_CODE__crash } from "../../../libraries/enums/issueTypes";
import { selectProfile } from "../../auth/selectors/authSelectors";
import {
  PERMISSION_RIGHT_CODE__considerIssueAsDoneByHeadquarters,
  PERMISSION_RIGHT_CODE__forwardIssueToHeadquarters,
  PERMISSION_RIGHT_CODE__forwardToServiceCompany,
  PERMISSION_RIGHT_CODE__markIssueAsDoneByHeadquarters,
  PERMISSION_RIGHT_CODE__markIssueAsDoneByServiceCompany,
  PERMISSION_RIGHT_CODE__markIssueAsDoneByUnit,
} from "../../../libraries/enums/permissionRights";
import {
  ISSUE_ACTION_CODE__considerIssueAsDoneByHeadquarters,
  ISSUE_ACTION_CODE__forwardIssueToHeadquarters,
  ISSUE_ACTION_CODE__forwardToServiceCompany,
  ISSUE_ACTION_CODE__markIssueAsDoneByHeadquarters,
  ISSUE_ACTION_CODE__markIssueAsDoneByServiceCompany,
  ISSUE_ACTION_CODE__markIssueAsDoneByUnit,
  ISSUE_ACTION_CODE__resetIssue,
} from "../../../libraries/enums/issueActions";
import { USER_TYPE_CODE__headquarters } from "../../../libraries/enums/userTypes";
import {
  ISSUE_PROGRESS_ITEM_TYPE_CODE__consideredAsDoneByHeadquarters,
  ISSUE_PROGRESS_ITEM_TYPE_CODE__markedAsDoneByServiceCompany,
  ISSUE_PROGRESS_ITEM_TYPE_CODE__markedAsDoneByUnit,
} from "../../../libraries/enums/issueProgressItemTypes";

const issueListSelector = issueEntityAdapter.getSelectors(
  (state: RootState) => state.issue.list
);

export const selectIssueIds = issueListSelector.selectIds;
export const selectIssueEntities = issueListSelector.selectEntities;
export const selectIssuesAll = issueListSelector.selectAll;
export const selectIssueById = issueListSelector.selectById;

export const selectListFetching = (state: RootState) =>
  state.issue.listFetching;

export const selectSelectedIssue = (state: RootState) =>
  state.issue.selectedIssue;
export const selectHasSelectedIssue = (state: RootState) =>
  !!state.issue.selectedIssue;
export const selectSelectedIssueMode = (state: RootState) =>
  state.issue.selectedIssueMode;

export const selectSelectedIssueClosingDialog = (state: RootState) =>
  state.issue.selectedIssueClosingDialog;

export const selectMarkingAsDoneConfirmDialog = (state: RootState) =>
  state.issue.markingAsDoneConfirmDialog;

export const selectSelectedIssueProperty = createSelector(
  [selectSelectedIssue, (state, args) => args.property],
  (issue, property) => {
    if (!issue) {
      return null;
    }

    // @ts-ignore
    return issue[property];
  }
);

export const selectIssueFilter = (state: RootState): IssueFilterType =>
  state.issue.filter;
export const selectIssueFilterIsEnabled = createSelector(
  [selectIssueFilter],
  (f) => {
    return (
      !!f.dateFrom ||
      !!f.dateTo ||
      f.statusIds.length > 0 ||
      f.typeIds.length > 0 ||
      f.unitIds.length > 0 ||
      f.brandIds.length > 0 ||
      f.brandGroupIds.length > 0 ||
      f.serviceCompanyIds.length > 0 ||
      f.serviceTechnicianIds.length > 0 ||
      f.hideIssueProgressItemTypeIds.length > 0 ||
      f.showClosed ||
      f.showManagedByFieldManagers
    );
  }
);
export const selectIssueFilterProperty = createSelector(
  [selectIssueFilter, (state, args) => args.property],
  (filter, property) => {
    if (!filter) {
      return null;
    }

    // @ts-ignore
    return filter[property];
  }
);

export const selectIssuesFilteredAndSorted = createSelector(
  [selectIssuesAll, selectIssueFilter, selectUnitEntities, selectProfile],
  (issues, filter, unitEntities, profile) => {
    if (!profile) {
      return [];
    }

    const emptyOrInArray = (
      search: string | null,
      array: string[]
    ): boolean => {
      if (array.length === 0) {
        return true;
      }
      if (!search) {
        return false;
      }
      return array.includes(search);
    };

    const showManagedByFieldManager = (issue: IssueType) => {
      if (!!issue.forwardedToHeadquartersAt) {
        return true;
      }
      if (profile.type === USER_TYPE_CODE__headquarters) {
        return (
          issue.managedByFieldManager === filter.showManagedByFieldManagers
        );
      }
      return true;
    };

    const hideIssueProgressItemType = (issue: IssueType) => {
      if (filter.hideIssueProgressItemTypeIds.length === 0) {
        return true;
      }
      if (
        filter.hideIssueProgressItemTypeIds.includes(
          ISSUE_PROGRESS_ITEM_TYPE_CODE__markedAsDoneByUnit
        )
      ) {
        return !issue.markedAsDoneByUnitAt;
      }
      if (
        filter.hideIssueProgressItemTypeIds.includes(
          ISSUE_PROGRESS_ITEM_TYPE_CODE__markedAsDoneByServiceCompany
        )
      ) {
        return !issue.markedAsDoneByServiceCompanyAt;
      }
      if (
        filter.hideIssueProgressItemTypeIds.includes(
          ISSUE_PROGRESS_ITEM_TYPE_CODE__consideredAsDoneByHeadquarters
        )
      ) {
        return !issue.consideredAsDoneByHeadquartersAt;
      }
      return true;
    };

    return issues
      .filter((issue) => {
        // @ts-ignore
        const unit = unitEntities[issue.unitId];

        return (
          (!filter.dateFrom ||
            issue.createdAt.split(/[T ]/i, 1)[0] >=
              filter.dateFrom.split(/[T ]/i, 1)[0]) &&
          (!filter.dateTo ||
            issue.createdAt.split(/[T ]/i, 1)[0] <=
              filter.dateTo.split(/[T ]/i, 1)[0]) &&
          emptyOrInArray(issue.statusId, filter.statusIds) &&
          emptyOrInArray(issue.typeId, filter.typeIds) &&
          emptyOrInArray(issue.unitId as unknown as string, filter.unitIds) &&
          emptyOrInArray(
            issue.serviceCompanyId as unknown as string,
            filter.serviceCompanyIds
          ) &&
          emptyOrInArray(
            issue.serviceTechnicianId as unknown as string,
            filter.serviceTechnicianIds
          ) &&
          emptyOrInArray(unit?.brandId || null, filter.brandIds) &&
          showManagedByFieldManager(issue) &&
          hideIssueProgressItemType(issue) &&
          issue.open === !filter.showClosed
        );
      })
      .sort((a, b) => {
        return -a.createdAt.localeCompare(b.createdAt);
      });
  }
);

export const selectIssuesFilteredAndSortedCount = createSelector(
  [selectIssuesFilteredAndSorted],
  (issues) => {
    return issues.length;
  }
);

export const selectListConfig = (state: RootState) => {
  return state.issue.listConfig;
};

export const selectSelectedIssuePrevNextIds = createSelector(
  [selectIssuesFilteredAndSorted, selectSelectedIssue],
  (issues, issue) => {
    if (!issue) {
      return {
        prev: null,
        next: null,
      };
    }
    const index = issues.findIndex((i) => i.id === issue.id);
    if (index === -1) {
      return {
        prev: null,
        next: null,
      };
    }
    if (index === 0) {
      return {
        prev: null,
        next: issues[index + 1]?.id || null,
      };
    }
    if (index === issues.length - 1) {
      return {
        prev: issues[index - 1]?.id || null,
        next: null,
      };
    }
    return {
      prev: issues[index - 1]?.id || null,
      next: issues[index + 1]?.id || null,
    };
  }
);

export const selectIssueIdsFilteredAndSorted = createSelector(
  [selectIssuesFilteredAndSorted, selectListConfig],
  (issues, listConfig) => {
    return issues
      .map((issue) => {
        return issue.id;
      })
      .slice(
        listConfig.page * listConfig.pageSize,
        (listConfig.page + 1) * listConfig.pageSize
      );
  }
);

export const selectBrandsWithStats = createSelector(
  [selectIssuesAll, selectBrands, selectUnits],
  (issues, brands, units) => {
    return brands.map((brand) => {
      const unitIdsOfBrand = units
        .filter((u) => (u.brandId as unknown as string) === brand.id)
        .map((u) => u.id);
      return {
        ...brand,
        stats: {
          [ISSUE_STATUS_CODE__new]: issues.filter(
            (i) =>
              i.statusId === ISSUE_STATUS_CODE__new &&
              unitIdsOfBrand.includes(i.unitId as unknown as string)
          ).length,
          [ISSUE_TYPE_CODE__crash]: issues.filter(
            (i) =>
              i.typeId === ISSUE_TYPE_CODE__crash &&
              unitIdsOfBrand.includes(i.unitId as unknown as string)
          ).length,
          [ISSUE_STATUS_CODE__inProgress]: issues.filter(
            (i) =>
              i.statusId === ISSUE_STATUS_CODE__inProgress &&
              unitIdsOfBrand.includes(i.unitId as unknown as string)
          ).length,
        },
      };
    });
  }
);

export const selectIssueActionCodesByIssueIdAndProfile = createSelector(
  [selectProfile, selectIssueEntities, (state, args) => args.issueId],
  (profile, issues, issueId) => {
    const actionCodes = [];

    if (!!profile) {
      const rightIds = profile.permission.permissionRightIds;
      const issue = issues[issueId];
      if (!!issue) {
        const statusId = issue.statusId;

        if (
          [ISSUE_STATUS_CODE__new, ISSUE_STATUS_CODE__inProgress].includes(
            statusId
          ) &&
          rightIds.includes(
            PERMISSION_RIGHT_CODE__forwardIssueToHeadquarters
          ) &&
          !issue.forwardedToHeadquartersAt
        ) {
          actionCodes.push(ISSUE_ACTION_CODE__forwardIssueToHeadquarters);
        }

        if (
          [ISSUE_STATUS_CODE__new, ISSUE_STATUS_CODE__inProgress].includes(
            statusId
          ) &&
          rightIds.includes(PERMISSION_RIGHT_CODE__markIssueAsDoneByUnit) &&
          !issue.markedAsDoneByUnitAt
        ) {
          actionCodes.push(ISSUE_ACTION_CODE__markIssueAsDoneByUnit);
        }

        if (
          [ISSUE_STATUS_CODE__inProgress].includes(statusId) &&
          rightIds.includes(PERMISSION_RIGHT_CODE__forwardToServiceCompany) &&
          !issue.forwardedToServiceCompanyAt
        ) {
          actionCodes.push(ISSUE_ACTION_CODE__forwardToServiceCompany);
        }

        if (
          [ISSUE_STATUS_CODE__inProgress].includes(statusId) &&
          rightIds.includes(
            PERMISSION_RIGHT_CODE__markIssueAsDoneByServiceCompany
          ) &&
          !issue.markedAsDoneByServiceCompanyAt
        ) {
          actionCodes.push(ISSUE_ACTION_CODE__markIssueAsDoneByServiceCompany);
        }

        if (
          [ISSUE_STATUS_CODE__new, ISSUE_STATUS_CODE__inProgress].includes(
            statusId
          ) &&
          rightIds.includes(
            PERMISSION_RIGHT_CODE__markIssueAsDoneByHeadquarters
          ) &&
          !issue.markedAsDoneByHeadquartersAt
        ) {
          actionCodes.push(ISSUE_ACTION_CODE__markIssueAsDoneByHeadquarters);
        }

        if (
          [ISSUE_STATUS_CODE__new, ISSUE_STATUS_CODE__inProgress].includes(
            statusId
          ) &&
          rightIds.includes(
            PERMISSION_RIGHT_CODE__considerIssueAsDoneByHeadquarters
          ) &&
          !issue.consideredAsDoneByHeadquartersAt
        ) {
          actionCodes.push(
            ISSUE_ACTION_CODE__considerIssueAsDoneByHeadquarters
          );
        }

        if (
          [ISSUE_STATUS_CODE__inProgress, ISSUE_STATUS_CODE__done].includes(
            statusId
          ) &&
          rightIds.includes(
            PERMISSION_RIGHT_CODE__markIssueAsDoneByHeadquarters
          )
        ) {
          actionCodes.push(ISSUE_ACTION_CODE__resetIssue);
        }
      }
    }

    return actionCodes;
  }
);
