import { RevisionSubjectType, RevisionType } from "../../../../types";
import { Chip } from "@mui/material";
import React from "react";
import DescriptionIcon from "@mui/icons-material/Description";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { buildRevision } from "../../../revision/builders/revision.builder";
import revisionSlice from "../../../revision/slices/revisionSlice";
import { selectUnitBySelectedUnitId } from "../../../enum/selectors/enumSelectors";
import { handleDownloadRevision } from "../../../revision/utils/revision.utils";
import { selectProfileHasPermissionRight } from "../../../common/selectors/commonSelectors";
import { PERMISSION_RIGHT_CODE__createRevision } from "../../../../libraries/enums/permissionRights";

const ChipRevision = (props: {
  size: "small" | "medium" | undefined;
  revision?: RevisionType;
  revisionSubject: RevisionSubjectType;
}) => {
  const { revision, revisionSubject, size = "small" } = props;

  const dispatch = useAppDispatch();
  const unit = useAppSelector(selectUnitBySelectedUnitId);
  const hasPermissionRightCreateRevision = useAppSelector((state) =>
    selectProfileHasPermissionRight(state, {
      permissionRight: PERMISSION_RIGHT_CODE__createRevision,
    })
  );

  const handleClick = () => {
    if (!!revision) {
      dispatch(handleDownloadRevision(revision));
    } else {
      if (hasPermissionRightCreateRevision) {
        const revision = buildRevision();
        revision.revisionSubjectId = revisionSubject.id;
        if (!!unit) {
          revision.unitId = unit.id;
          revision.unitStoreNumber = unit.storeNumber;
        }
        dispatch(revisionSlice.actions.selectedRevisionSet(revision));
        dispatch(revisionSlice.actions.selectedRevisionModeSet("create"));
      }
    }
  };

  return (
    <Chip
      onClick={() => {
        handleClick();
      }}
      sx={{
        cursor: "pointer",
        my: 0.25,
        opacity: !!revision ? 1 : 0.3,
      }}
      color={"primary"}
      icon={!!revision ? <DescriptionIcon /> : <DescriptionOutlinedIcon />}
      label={revisionSubject.title}
      size={size}
    />
  );
};

export default ChipRevision;
