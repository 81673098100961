import {
  AnyAction,
  createListenerMiddleware,
  ListenerMiddlewareInstance,
} from "@reduxjs/toolkit";
import { ListenerApiType } from "../../../types/api.types";
import issueSlice from "../../issue/slices/issueSlice";
import { RootState } from "../../../app/store";
import shareEntitySlice from "../slices/shareEntity.slice";
import {
  selectSelectedIssueMode,
  selectSelectedIssueProperty,
} from "../../issue/selectors/issueSelectors";

const shareEntityMiddleware: ListenerMiddlewareInstance =
  createListenerMiddleware();

//TODO
//pro zkoušení: VK
// http://localhost:3000/issues?id=369bf6f2-c184-43ef-855c-70bb26613a6d&a=1c5dd7a436ae449abaace4ac093657b6

//při otevření detailu požadavku
shareEntityMiddleware.startListening({
  predicate: (action, currentState, originalState) => {
    return (
      !!selectSelectedIssueProperty(currentState, { property: "id" }) &&
      selectSelectedIssueMode(currentState as RootState) === "read"
    );
  },
  effect: (action: AnyAction, listenerApi: ListenerApiType) => {
    const state: RootState = listenerApi.getState() as RootState;
    const id = selectSelectedIssueProperty(state, { property: "id" });
    window.history.replaceState(
      null,
      "",
      window.location.pathname + "?id=" + id
    );
  },
});

//při zavření detailu požadavku
shareEntityMiddleware.startListening({
  predicate: (action, currentState, originalState) => {
    return !selectSelectedIssueProperty(currentState, { property: "id" });
  },
  effect: (action: AnyAction, listenerApi: ListenerApiType) => {
    window.history.replaceState(null, "", window.location.pathname);
  },
});

//po nasetování listu požadavků se zobrazí detail požadavku (dle uložené entryLocation)
shareEntityMiddleware.startListening({
  type: issueSlice.actions.issueListSet.type,
  effect: (action: AnyAction, listenerApi: ListenerApiType) => {
    const state: RootState = listenerApi.getState() as RootState;
    const entryLocation = state.shareEntity.entryLocation;
    if (
      entryLocation.pathname !== "" &&
      entryLocation.pathname === window.location.pathname &&
      entryLocation.pathname === "/issues" &&
      entryLocation.params.hasOwnProperty("id")
    ) {
      // @ts-ignore
      const id = entryLocation.params["id"];
      listenerApi.dispatch(issueSlice.actions.selectedIssueSetById(id));
    }
    listenerApi.dispatch(
      shareEntitySlice.actions.entryLocationSave({ pathname: "", params: {} })
    );
  },
});

export default shareEntityMiddleware;
