import { RootState } from "../../../app/store";
import {
  brandEntityAdapter,
  fieldManagerEntityAdapter,
  mandantEntityAdapter,
  revisionCompanyEntityAdapter,
  revisionSubjectEntityAdapter,
  serviceCompanyEntityAdapter,
  serviceTechnicianEntityAdapter,
  unitAddressEntityAdapter,
  unitEntityAdapter,
} from "../slices/enumSlice";
import { createSelector } from "@reduxjs/toolkit";
import { UnitType } from "../../../types";

const brandSelector = brandEntityAdapter.getSelectors(
  (state: RootState) => state.enum.brand
);
const mandantSelector = mandantEntityAdapter.getSelectors(
  (state: RootState) => state.enum.mandant
);
const fieldManagerSelector = fieldManagerEntityAdapter.getSelectors(
  (state: RootState) => state.enum.fieldManager
);
const unitAddressSelector = unitAddressEntityAdapter.getSelectors(
  (state: RootState) => state.enum.unitAddress
);
const unitSelector = unitEntityAdapter.getSelectors(
  (state: RootState) => state.enum.unit
);
const serviceCompanySelector = serviceCompanyEntityAdapter.getSelectors(
  (state: RootState) => state.enum.serviceCompany
);
const serviceTechnicianSelector = serviceTechnicianEntityAdapter.getSelectors(
  (state: RootState) => state.enum.serviceTechnician
);
const revisionCompanySelector = revisionCompanyEntityAdapter.getSelectors(
  (state: RootState) => state.enum.revisionCompany
);
const revisionSubjectSelector = revisionSubjectEntityAdapter.getSelectors(
  (state: RootState) => state.enum.revisionSubject
);

export const selectBrandById = brandSelector.selectById;
export const selectUnitAddressById = unitAddressSelector.selectById;
export const selectUnitById = unitSelector.selectById;
export const selectServiceCompanyById = serviceCompanySelector.selectById;
export const selectServiceTechnicianById = serviceTechnicianSelector.selectById;
export const selectRevisionCompanyById = revisionCompanySelector.selectById;
export const selectRevisionSubjectById = revisionSubjectSelector.selectById;

export const selectUnitIds = unitSelector.selectIds;

const selectAllUnits = unitSelector.selectAll;
export const selectBrands = brandSelector.selectAll;
export const selectUnitAddresses = unitAddressSelector.selectAll;
export const selectMandants = mandantSelector.selectAll;
export const selectFieldManagers = fieldManagerSelector.selectAll;
export const selectServiceCompanies = serviceCompanySelector.selectAll;
export const selectServiceTechnicians = serviceTechnicianSelector.selectAll;
export const selectRevisionCompanies = revisionCompanySelector.selectAll;
export const selectRevisionSubjects = revisionSubjectSelector.selectAll;

export const selectUnitEntities = unitSelector.selectEntities;
export const selectBrandEntities = brandSelector.selectEntities;
export const selectUnitAdressEntities = unitAddressSelector.selectEntities;
export const selectMandantEntities = mandantSelector.selectEntities;
export const selectFieldManagerEntities = fieldManagerSelector.selectEntities;
export const selectServiceCompanyEntities =
  serviceCompanySelector.selectEntities;
export const selectServiceTechnicianEntities =
  serviceTechnicianSelector.selectEntities;
export const selectRevisionSubjectEntities =
  revisionSubjectSelector.selectEntities;

export const selectSelectedUnitId = (state: RootState) => {
  return state.issue.selectedUnitId;
};
export const selectUnitBySelectedUnitId = createSelector(
  [selectUnitEntities, selectSelectedUnitId],
  (entities, unitId) => {
    if (!!unitId) {
      return entities[unitId];
    }
    return null;
  }
);
export const selectBrandBySelectedUnitId = createSelector(
  [selectBrandEntities, selectUnitBySelectedUnitId],
  (entities, unit) => {
    if (!!unit) {
      // @ts-ignore
      const brand = entities[unit.brandId];
      if (!!brand) {
        return brand;
      }
    }
    return null;
  }
);

export const selectBrandByUnitId = (state: RootState, unitId: any) => {
  const unit = selectUnitById(state, unitId);
  if (!!unit) {
    // @ts-ignore
    const brand = selectBrandById(state, unit.brandId);
    if (!!brand) {
      return brand;
    }
  }
  return null;
};

export const selectUnitAddressByUnitId = (state: RootState, unitId: any) => {
  const unit = selectUnitById(state, unitId);
  if (!!unit) {
    // @ts-ignore
    const unitAddress = selectUnitAddressById(state, unit.addressId);
    if (!!unitAddress) {
      return unitAddress;
    }
  }
  return null;
};

export const selectUnits = createSelector(
  [selectAllUnits, selectBrandEntities],
  (allUnits, brandEntities) => {
    return allUnits.filter((unit: UnitType) => {
      return !!brandEntities[unit.brandId as unknown as string];
    });
  }
);

export const selectFlattenUnits = createSelector(
  [
    selectUnits,
    selectBrandEntities,
    selectUnitAdressEntities,
    selectMandantEntities,
    selectFieldManagerEntities,
  ],
  (
    units,
    brandEntities,
    unitAddressEntities,
    mandantEntities,
    fieldManagerEntities
  ) => {
    return units
      .map((unit: UnitType) => {
        // @ts-ignore
        const brand = brandEntities[unit.brandId];
        // @ts-ignore
        const address = unitAddressEntities[unit.unitAddressId];
        // @ts-ignore
        const mandant = mandantEntities[unit.mandantId];
        // @ts-ignore
        const fieldManager = fieldManagerEntities[unit.fieldManagerId];

        return {
          ...unit,
          brand,
          address,
          mandant,
          fieldManager,
        };
      })
      .sort((a, b) => {
        const aX = (a.brand?.code || "") + a.storeNumber;
        const bX = (b.brand?.code || "") + b.storeNumber;
        return aX.localeCompare(bX);
      });
  }
);
