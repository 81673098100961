import { v4 as uuidv4 } from "uuid";
import { AssetType } from "../../../types/asset.types";

export const buildAsset = () => {
  const now = new Date();
  const asset: AssetType = {
    id: uuidv4(),
    createdAt: now.toISOString(),
    unitId: "",
    title: "",
    category: null,
    description: null,
    enabled: true,
    inventoryNumber: null,
    origin: null,
    ownershipTypeId: "own",
    producerNumber: null,
    serialNumber: null,
    statusId: "new",
    files: [],
    qrCodes: [],
  };

  return asset;
};
