import React, { useEffect } from "react";
import {
  fetchProfile,
  getAuthTokenViaURLAccessToken,
  getURLAccessToken,
  loadAuthToken,
  logOut,
  saveAuthToken,
} from "../../utils/utils";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../../app/store";
import { useNavigate } from "react-router-dom";
import Loader from "../../../common/components/Loader/Loader";
import { loadProfile } from "../../../../app/profile.utils";

type AuthGuardProps = {
  children: React.ReactNode;
};

const AuthGuard = (props: AuthGuardProps) => {
  const dispatch = useAppDispatch();
  const profile = useAppSelector((state: RootState) => state.auth.profile);

  const navigate = useNavigate();

  const init = async () => {
    getURLAccessToken().then((urlAccessToken) => {
      if (!!urlAccessToken) {
        getAuthTokenViaURLAccessToken(urlAccessToken)
          .then((response) => {
            if (response.status === 200) {
              // @ts-ignore
              const authToken = response.data.token;
              saveAuthToken(authToken);
              loadProfile(authToken, { dispatch, navigate });
            } else {
              logOut(dispatch, navigate);
            }
          })
          .catch((e) => {
            logOut(dispatch, navigate);
          });
      } else {
        const authToken = loadAuthToken();
        loadProfile(authToken, { dispatch, navigate });
      }
    });
  };

  useEffect(() => {
    init();
  }, []);

  if (!profile) {
    return (
      <>
        <Loader />
      </>
    );
  }

  return <>{props.children}</>;
};

export default AuthGuard;
