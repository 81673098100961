import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { getBrandLogoUrlByBrandCode } from "../../../../libraries/brands";
import { grey } from "@mui/material/colors";
import CloseButton from "../../../common/components/CloseButton/CloseButton";
import {
  selectBrandBySelectedUnitId,
  selectUnitBySelectedUnitId,
} from "../../../enum/selectors/enumSelectors";
import { useTheme } from "@mui/material/styles";
import issueSlice from "../../../issue/slices/issueSlice";
import TabInfo from "./TabInfo";
import TabDocuments from "./TabDocuments";
import { ProfileType } from "../../../../types";
import {
  PERMISSION_RIGHT_CODE__createDocument,
  PERMISSION_RIGHT_CODE__createRevision,
  PERMISSION_RIGHT_CODE__readDocument,
  PERMISSION_RIGHT_CODE__readRevision,
} from "../../../../libraries/enums/permissionRights";
import { selectProfile } from "../../../auth/selectors/authSelectors";
import TabRevisions from "./TabRevisions";
import { MoreHoriz } from "@mui/icons-material";
import UnitDialogFastFilter from "./UnitDialogFastFilter";

const getTabItems = (profile: ProfileType) => {
  const INFO = { value: "info", label: "Základní informace" };
  const REVISIONS = { value: "revisions", label: "Revize a kontroly" };
  const DOCUMENTS = { value: "documents", label: "Dokumenty" };

  const items = [INFO];

  if (
    profile.permission.permissionRightIds.some((p) => {
      return [
        PERMISSION_RIGHT_CODE__createRevision,
        PERMISSION_RIGHT_CODE__readRevision,
      ].includes(p);
    })
  ) {
    items.push(REVISIONS);
  }

  if (
    profile.permission.permissionRightIds.some((p) => {
      return [
        PERMISSION_RIGHT_CODE__createDocument,
        PERMISSION_RIGHT_CODE__readDocument,
      ].includes(p);
    })
  ) {
    items.push(DOCUMENTS);
  }

  return items;
};

const UnitDialog = () => {
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  const dispatch = useAppDispatch();
  const profile = useAppSelector(selectProfile);
  const unit = useAppSelector(selectUnitBySelectedUnitId);
  const brand = useAppSelector(selectBrandBySelectedUnitId);

  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState("info");

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      dispatch(issueSlice.actions.selectedUnitIdSet(null));
    }, 500);
  };

  useEffect(() => {
    if (!!unit) {
      setOpen(true);
    }
  }, [unit]);

  if (!unit || !brand || !profile) {
    return <></>;
  }

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      maxWidth={"md"}
      fullWidth={true}
      fullScreen={mdUp ? false : true}
      sx={{
        "& .MuiDialog-container": {
          alignItems: "flex-start",
        },
      }}
    >
      <DialogTitle sx={{ p: 0 }}>
        <Box
          sx={{
            m: 2,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box display="flex" alignItems="center">
            <Avatar
              src={getBrandLogoUrlByBrandCode(brand.code)}
              sx={{ mr: 1 }}
            />
            <Box>
              <Typography
                variant="body2"
                sx={{ color: grey[600], fontWeight: 600 }}
              >
                {unit.title}
              </Typography>
              <Typography variant="body2" sx={{ whiteSpace: "nowrap" }}>
                {unit.subtitle || ""}
              </Typography>
            </Box>
          </Box>
          <CloseButton onClick={handleClose} />
        </Box>
        <Divider />
        {getTabItems(profile).length > 1 && (
          <Tabs
            textColor={"secondary"}
            indicatorColor={"secondary"}
            value={tab}
            onChange={(event, newValue) => {
              setTab(newValue);
            }}
          >
            {getTabItems(profile).map((item) => {
              return (
                <Tab key={item.value} label={item.label} value={item.value} />
              );
            })}
          </Tabs>
        )}
      </DialogTitle>
      <Divider />
      <DialogContent>
        {tab === "info" && <TabInfo unit={unit} />}
        {tab === "revisions" && <TabRevisions unit={unit} />}
        {tab === "documents" && <TabDocuments unit={unit} />}
      </DialogContent>
    </Dialog>
  );
};

export default UnitDialog;
