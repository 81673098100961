import { Box } from "@mui/system";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import {
  selectAssetIdsSearchedAndSorted,
  selectAssetsSearchedAndSortedCount,
  selectListConfig,
  selectListFetchingStatus,
} from "../../selectors/asset.selector";
import {
  CircularProgress,
  Divider,
  Grid,
  Hidden,
  TablePagination,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import assetSlice from "../../slices/asset.slice";
import { fieldCompanies } from "./fields/fieldCompanies";
import { fieldUnit } from "./fields/fieldUnit";
import { fieldAsset } from "./fields/fieldAsset";
import { fieldMovements } from "./fields/fieldMovements";
import { fieldIdentifiers } from "./fields/fieldIdentifiers";
import { fieldUnitPlacement } from "./fields/fieldUnitPlacement";

const AssetList = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const fetchingStatus = useAppSelector(selectListFetchingStatus);
  const rows = useAppSelector(selectAssetIdsSearchedAndSorted);
  const listConfig = useAppSelector(selectListConfig);
  const count = useAppSelector(selectAssetsSearchedAndSortedCount);

  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));

  const columnsSizes = lgUp ? [3, 2, 2, 3] : [3, 2, 2, 3];

  const columns = lgUp
    ? [fieldAsset, fieldUnit, fieldUnitPlacement, fieldIdentifiers]
    : [fieldAsset, fieldUnit, fieldUnitPlacement, fieldIdentifiers];

  const handlePageChange = (event: any, page: any) => {
    dispatch(assetSlice.actions.listConfigSet({ page: page }));
  };

  const handleRowsPerPageChange = (event: any) => {
    dispatch(
      assetSlice.actions.listConfigSet({ pageSize: event.target.value })
    );
  };

  return (
    <>
      <Hidden mdDown>
        <Box
          sx={{
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          <Grid container>
            {columns.map((column, index) => {
              return (
                <Grid key={column.code} item xs={columnsSizes[index]} p={2}>
                  <Typography variant="subtitle2">{column.label}</Typography>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Hidden>

      <Hidden mdUp>
        <Divider />
      </Hidden>

      <Box
        hidden={!(fetchingStatus === "fetching")}
        sx={{ py: 5, textAlign: "center" }}
      >
        <CircularProgress />
      </Box>

      <Box
        hidden={!(fetchingStatus === "failure")}
        sx={{ color: "rgba(0, 0, 0, 0.24)", p: 2 }}
      >
        Není zde žádné zařízení.
      </Box>

      <Box
        hidden={!(fetchingStatus === "init")}
        sx={{ color: "rgba(0, 0, 0, 0.24)", p: 2 }}
      >
        Zadejte nejdříve kritéria pro vyhledání zařízení.
      </Box>

      <Box hidden={!(fetchingStatus === "success")} sx={{}}>
        {rows.map((id, index) => {
          return (
            <Grid
              container
              // onClick={() => {
              //   dispatch(revisionSlice.actions.selectedRevisionSetById(id));
              // }}
              sx={{
                outline: 0,
                // cursor: "pointer",
                "&:hover": {
                  bgcolor: "rgb(225, 245, 254)",
                },
                background:
                  index % 2 === 1 ? "rgba(0, 0, 0, 0.02)" : "rgba(0, 0, 0, 0)",
                borderBottom: "1px solid #ececec",
                "&:last-of-type": {
                  borderBottom: 0,
                },
              }}
              tabIndex={-1}
              key={id}
            >
              {columns.map((column, index) => {
                return (
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    alignItems="center"
                    md={columnsSizes[index]}
                    key={column.code}
                    onClick={() => {
                      dispatch(assetSlice.actions.selectedAssetSetById(id));
                    }}
                  >
                    {React.createElement(column.component as any, {
                      assetId: id,
                      isDetail: false,
                    })}
                  </Grid>
                );
              })}
            </Grid>
          );
        })}
      </Box>

      <Divider />

      <TablePagination
        rowsPerPageOptions={[5, 25, 50, 100]}
        component="div"
        count={count}
        rowsPerPage={listConfig.pageSize}
        page={listConfig.page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        labelRowsPerPage="Počet řádků"
        labelDisplayedRows={({ from, to, count }) =>
          `Zobrazeno ${from}–${to} z ${count} záznamů`
        }
        backIconButtonProps={{
          "aria-label": "předchozí",
        }}
        nextIconButtonProps={{
          "aria-label": "další",
        }}
      />
    </>
  );
};

export default AssetList;
